import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api from '../../config/api';
import './style.css';
import Stack from '@mui/material/Stack';
import BackIcon from './BackIcon';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import EyeIcon from '../../assets/icons/EyeIcon';
import LikeIcon from '../../assets/icons/LikeIcon';
import MessageIcon from '../../assets/icons/MessageIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import SocialShare from '../../components/SocialShare/SocialShare';
import useGetTask from '../../hooks/useGetTask';
import ChatBottomDrawer from '../../components/ChatBottomDrawer/ChatBottomDrawer';
import useSendLike from '../../hooks/useSendLike';
import useUpdateViewCount from '../../hooks/useUpdateViewCount';
import LikeCommentShareBox from '../../components/LikeCommentShareComponent/LikeCommentShareBox';
import LikeIconFilled from '../../assets/icons/LikeIconFilled';
import useIsUserLiked from '../../hooks/useIsUserLiked';
import HelmetMetaData from '../../components/ReactHelmet/ReactHealmet';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import VideoWatermarkIcon from '../../assets/icons/VideoWatermarkIcon';
import LoginModal from '../../components/LoginModal/Login';
import isGuestUser from '../../hooks/isGuestUser';

const getRecording = async (streamId) => {
  const { data } = await api.get(`/api/stream/recordings/${streamId}`);
  return data;
};

const RECORDED_VIDEO_SHARE_TEXT = 'Watch out this Video on bunpun - title #happybunpun';

const downloadVideo = async (downloadUrl, filename) => {
  try {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading the video:', error);
  }
};

const RecordedVideoPlayer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const streamId = queryParams.get('streamId');
  const taskId = queryParams.get('taskId');

  const navigate = useNavigate();
  const { data: task } = useGetTask(taskId);
  const { data: userIsLiked } = useIsUserLiked(taskId);
  const [isDownloading, setIsDownloading] = React.useState(false);
  console.log('inside RecordedVideoPlayer');

  const { data: recording, isLoading } = useQuery({
    queryKey: ['recording', streamId],
    queryFn: () => getRecording(streamId),
    refetchOnWindowFocus: false,
  });

  const [thumbnailUrls, setThumbnailUrls] = React.useState('');
  const [playBackUrls, setPlayBackUrls] = React.useState([]);
  const [downloadUrls, setDownloadUrls] = React.useState([]);
  const [playIndex, setPlayIndex] = React.useState(0);
  const assets = recording?.data?.asset;

  const downloadMultipleVideos = async (downloadUrls, filename) => {
    try {
      setIsDownloading(true);
      const blobs = await Promise.all(
        downloadUrls.map(async (url) => {
          const response = await fetch(url);
          return response.blob();
        }),
      );

      const combinedBlob = new Blob(blobs, { type: 'video/mp4' });
      const url = window.URL.createObjectURL(combinedBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      console.error('Error downloading the videos:', error);
    }
  };

  useEffect(() => {
    const playUrls = [];
    const downloadUrls = [];
    assets?.forEach((asset) => {
      setThumbnailUrls(asset?.asset?.thumbnail_url);
      playUrls.push(asset?.asset?.playback_url);
      downloadUrls.push(asset?.asset?.download_url);
    });
    setDownloadUrls(downloadUrls);
    setPlayBackUrls(playUrls);
  }, [assets]);

  console.log(recording);
  console.log('thumbnailUrl: ', thumbnailUrls);
  console.log('playBackUrl: ', playBackUrls);
  console.log('downloadUrl: ', downloadUrls);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const handleSocialShareDialog = () => {
    setOpenShareDialog(!openShareDialog);
  };
  const [openComments, setOpenComments] = React.useState(false);
  const { type, accepted, user } = recording?.data?.task || {};
  const userInfo = type === 'REQUEST' ? accepted?.user : user;
  const username = userInfo?.name;
  const avatarUrl = userInfo?.profile?.avatarUrl;

  const [isLiked, setIsLiked] = React.useState(userIsLiked?.data?.isLiked);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  useEffect(() => {
    setIsLiked(userIsLiked?.data?.isLiked);
  }, [userIsLiked?.data?.isLiked]);
  const { mutate: sendLikeMutate } = useSendLike(taskId);

  const { mutate: updateViews } = useUpdateViewCount(streamId, taskId);
  useEffect(() => updateViews(streamId, taskId), []);
  const handleDownload = async () => {
    const filename = `recording-${streamId}.mp4`;
    await downloadMultipleVideos(downloadUrls, filename);
  };

  if (isLoading) return <MyVideoLoading />;
  if (isDownloading) return <MyVideoLoading content={'The video is being downloaded. Please wait!'} />;

  return (
    <>
      <HelmetMetaData title={RECORDED_VIDEO_SHARE_TEXT.replace('title', task?.data?.title)} image={thumbnailUrls} />
      <Stack
        sx={{
          position: 'relative',
          mx: { sm: '-24px', xs: '-16px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100dvh',
        }}
      >
        <Box sx={{ position: 'absolute', bottom: 25, left: 25 }}>
          <VideoWatermarkIcon />
        </Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            zIndex: 10,
            width: '100%',
            px: { sm: '24px', xs: '16px' },
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack direction='row' gap={1.5} alignItems='center'>
            <IconButton onClick={() => navigate(-1)}>
              <BackIcon />
            </IconButton>
            <Stack direction='row' gap={0.5} alignItems='center'>
              <CustomAvatar name={username} avatarUrl={avatarUrl} size={24} fontSize={12} />
              <Typography variant='h6_dark' sx={{ color: '#fff' }}>
                {username}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' gap={1}>
            <Stack
              spacing={0.5}
              direction='row'
              sx={{
                padding: '4px 8px 4px 8px',
                backgroundColor: '#5F5D5D80',
                color: '#fff',
                borderRadius: '4px',
              }}
            >
              <EyeIcon />
              <Typography variant='caption_bold'>{task?.data?.viewers}</Typography>
            </Stack>
            <Stack
              sx={{
                padding: '4px 8px 4px 8px',
                backgroundColor: '#5F5D5D80',
                color: '#fff',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => (isGuestUser() ? setOpenLoginModal(true) : handleDownload())}
            >
              <DownloadIcon />
            </Stack>
          </Stack>

          <Typography
            sx={{
              position: 'absolute',
              top: '66px',
              left: '24px',
              whiteSpace: 'normal',
              color: '#fff',
            }}
            variant={'h5_semibold'}
          >
            {task?.data?.title}
          </Typography>
        </Stack>

        <Box
          display='flex'
          alignItems='flex-end'
          flexDirection='column'
          width='fit-content'
          justifyContent='center'
          gap={1}
          sx={{
            position: 'absolute',
            right: 16,
            top: '50%',
            transform: 'translate(0, -50%)',
            zIndex: 11,
            borderRadius: '12',
          }}
        >
          <LikeCommentShareBox
            Icon={isLiked ? LikeIconFilled : LikeIcon}
            value={task?.data?.likes}
            onClick={() => (isGuestUser() ? setOpenLoginModal(true) : sendLikeMutate(taskId))}
          />
          <LikeCommentShareBox
            Icon={MessageIcon}
            value={recording?.data?.task?.chats}
            onClick={() => (isGuestUser() ? setOpenLoginModal(true) : setOpenComments(true))}
          />
          <LikeCommentShareBox Icon={ShareIcon} onClick={handleSocialShareDialog} />
        </Box>
        <ReactPlayer
          url={playBackUrls[playIndex]}
          controls={true}
          width='100%'
          height='100dvh'
          playing={true}
          muted={false}
          volume={1}
          // light={thumbnailUrls}
          // playIcon={
          //   <Button variant='contained' sx={{ borderRadius: 50, p: 3 }} onClick={() => updateViews(streamId)}>
          //     <PlayIcon />
          //   </Button>
          // }
          onEnded={() => {
            if (playBackUrls.length < playIndex) {
              setPlayIndex(playIndex + 1);
            }
          }}
        />
      </Stack>

      <ChatBottomDrawer open={openComments} setOpen={setOpenComments} taskId={taskId} type='Recorded' />
      <SocialShare
        open={openShareDialog}
        setOpen={handleSocialShareDialog}
        url={window.location.href}
        text={`${RECORDED_VIDEO_SHARE_TEXT.replace('title', task?.data?.title)}`}
        imageUrl={thumbnailUrls}
      />
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
};

export default RecordedVideoPlayer;
