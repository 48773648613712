import React, { useEffect, useRef, useState } from 'react';
import LikeIcon from '../../assets/icons/LikeIcon';
import MessageIcon from '../../assets/icons/MessageIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';
import api from '../../config/api';
import { useForm, useWatch } from 'react-hook-form';
import useProfile from '../../hooks/useProfile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { socket } from '../../config/socket';
import CustomTextField from '../CustomTextField/CustomTextField';
import SendIcon from '../../assets/icons/SendIcon';
import ChatBox from '../ChatBox/ChatBox';
import SocialShare from '../SocialShare/SocialShare';
import useGetChats from '../../hooks/useGetChats';
import useSendLike from '../../hooks/useSendLike';
import LikeCommentShareBox from '../LikeCommentShareComponent/LikeCommentShareBox';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';
import useIsUserLiked from '../../hooks/useIsUserLiked';
import LikeIconFilled from '../../assets/icons/LikeIconFilled';
import GiftIcon from '../../assets/icons/GiftIcon';
import isGuestUser from '../../hooks/isGuestUser';
import SendGiftDrawer from '../SendGiftDrawer/SendGiftDrawer';
import LoginModal from '../LoginModal/Login';
import useSocket from '../../hooks/useSocket';

const sendChat = async ({ taskId, message }) => {
  await api.post(`/api/user/task/${taskId}/chat`, { text: message });
};

const sendGift = async ({ streamerId, giftType }) => {
  await api.post('api/stream/gift', { streamerId, [giftType]: 1 });
};

const PlayerSkin = ({ taskId, streamId, task, duration, isViewer = false }) => {
  const socialShareRef = useRef(null);
  const chatListRef = useRef(null);
  const { handleSubmit, control, reset } = useForm();
  const [messageWatch] = useWatch({ control, name: ['message'] });
  const { data: profile } = useProfile();
  const { data, ...rest } = useGetChats(taskId);
  const chats = useInfiniteQueryReducedResult(data);
  const queryClient = useQueryClient();
  const { data: userIsLiked } = useIsUserLiked(taskId);
  const [isLiked, setIsLiked] = React.useState(userIsLiked?.data?.isLiked);
  const [showGiftDrawer, setShowGiftDrawer] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const { socket, isConnected, connectionError, connectSocket, disconnectSocket } = useSocket();
  const streamer = task?.data?.accepted?.user ?? task?.data?.user;

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [chats]);
  useEffect(() => {
    let intervalId;
    if (!isConnected) {
      intervalId = setInterval(() => {}, 3000);
    } else {
      intervalId && clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isConnected]);

  useEffect(() => {
    setIsLiked(userIsLiked?.data?.isLiked);
  }, [userIsLiked?.data?.isLiked]);

  const { mutate: sendChatMutate } = useMutation({
    mutationFn: sendChat,
    onSettled: async () => {
      await queryClient.invalidateQueries(['chats', taskId]);
    },
  });
  const { mutate: sendLikeMutate } = useSendLike(taskId);

  const { mutate: sendGiftMutate } = useMutation({ mutationFn: sendGift });

  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const handleSocialShareDialog = () => {
    setOpenShareDialog(!openShareDialog);
  };

  useEffect(() => {
    // socket.connect();
    if (duration) socket.emit('join_room', { room: streamId, user: profile?.data?._id, duration: duration });
    else socket.emit('join_room', { room: streamId, user: profile?.data?._id });
  }, [duration, profile?.data?._id, streamId]);

  useEffect(() => {
    socket.on('receive_message', async (data) => {
      console.log(data);
      // await updateData(['chats', taskId], data);
      await queryClient.invalidateQueries(['chats', taskId]);
      await queryClient.invalidateQueries(['task', taskId]);
    });
    socket.on('receive_like', async (data) => {
      console.log(data);
      await queryClient.invalidateQueries(['task', taskId]);
      await queryClient.invalidateQueries(['chats', taskId]);
    });

    socket.on('receive_gift', async () => {
      await queryClient.invalidateQueries(['task', taskId]);
    });
    return () => {
      socket.off('receive_message');
      socket.off('receive_like');
      socket.off('receive_gift');
    };
  }, [queryClient, taskId]);

  const onMessageSend = async (value) => {
    socket.emit('send_message', {
      userDetails: {
        name: profile?.data?.name,
        avatarUrl: profile?.data?.profile?.avatarUrl,
      },
      room: streamId,
      message: value.message,
    });
    console.log(value.message);
    sendChatMutate({ taskId, message: value.message });
    reset({ message: '' });
    await queryClient.invalidateQueries(['chats', taskId]);
    await queryClient.invalidateQueries(['task', taskId]);
  };
  const onSendLike = async () => {
    socket.emit('send_like', {
      userDetails: {
        name: profile?.data?.name,
        avatarUrl: profile?.data?.profile?.avatarUrl,
      },
      room: streamId,
    });
    sendLikeMutate(taskId);

    await queryClient.invalidateQueries(['task', taskId]);
  };
  let chatHeight =
    document.documentElement.scrollHeight - socialShareRef?.current?.getBoundingClientRect()?.bottom - 100;
  chatHeight = showGiftDrawer && isViewer ? chatHeight - 50 : chatHeight;
  const LIVE_STREAM_INVITE = process.env.REACT_APP_LIVE_STREAM_INVITE.replace('user', profile?.data?.name);
  useEffect(() => {
    messageWatch?.length > 0 && setShowGiftDrawer(false);
  }, [messageWatch?.length]);

  console.log(messageWatch?.length === 0 && isViewer);
  console.log('isViewer: ', isViewer);
  console.log('messageWatch?.length: ', messageWatch?.length);
  console.log('isGuestUser: ', isGuestUser());

  const countGifts = () => {
    if (task?.data?.gifts) {
      return Object.values(task.data.gifts).reduce((acc, curr) => acc + curr, 0);
    } else return 0;
  };

  return (
    <>
      {console.log({ chats })}
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexDirection={'column'}
        width={'fit-content'}
        justifyContent={'center'}
        gap={1}
        sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translate(0, -50%)', zIndex: 11 }}
        ref={socialShareRef}
      >
        {console.dir('socket-1', socket)}
        <LikeCommentShareBox
          Icon={isLiked ? LikeIconFilled : LikeIcon}
          value={task?.data?.likes}
          onClick={() => (isGuestUser() ? setOpenLoginModal(true) : onSendLike())}
        />
        <LikeCommentShareBox
          Icon={MessageIcon}
          value={task?.data?.chats}
          onClick={() => isGuestUser() && setOpenLoginModal(true)}
        />
        <LikeCommentShareBox
          Icon={GiftIcon}
          variant='light'
          value={countGifts()}
          onClick={() => isGuestUser() && setOpenLoginModal(true)}
        />
        <LikeCommentShareBox Icon={ShareIcon} onClick={handleSocialShareDialog} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: socialShareRef?.current?.getBoundingClientRect()?.bottom + 1,
          // bottom: 24,
          // left: { sm: 24, xs: 16 },
          // right: { sm: 24, xs: 16 },
          zIndex: 10,
          overflow: 'hidden',
          bottom: 0,
          width: '100%',
          padding: '16px 16px 25px 16px',
          background: 'linear-gradient(to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0))',
        }}
      >
        {/* <Stack spacing={1.5}> */}
        <Stack
          spacing={0.6}
          sx={{
            maxHeight: chatHeight,
            height: chatHeight,
            overflowY: 'scroll',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
          }}
          ref={chatListRef}
        >
          {chats?.map((chat, index) => (
            <ChatBox key={index} chat={chat} index={index} totalCount={chats?.length} {...rest} />
          ))}
        </Stack>
        {!isGuestUser() && (
          <form onSubmit={handleSubmit(onMessageSend)}>
            <CustomTextField
              name={'message'}
              control={control}
              type={'text'}
              endAdornment={
                <InputAdornment position={'end'}>
                  {isViewer && messageWatch?.length === 0 ? (
                    <IconButton onClick={() => setShowGiftDrawer(!showGiftDrawer)}>
                      <GiftIcon />
                    </IconButton>
                  ) : (
                    <IconButton type={'submit'}>
                      <SendIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              backgroundColor='rgba(25, 25, 25, 0.4)'
              borderColor='rgba(255, 255, 255, 0.3)'
              color='#ffffff'
              borderRadius='8px'
            />
          </form>
        )}
        {/* </Stack> */}
      </Box>
      <SocialShare
        open={openShareDialog}
        setOpen={handleSocialShareDialog}
        url={window.location.href}
        text={LIVE_STREAM_INVITE}
      />
      <SendGiftDrawer
        showGiftDrawer={showGiftDrawer}
        setShowGiftDrawer={setShowGiftDrawer}
        streamer={streamer}
        taskId={taskId}
        streamId={streamId}
      />
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
};

export default PlayerSkin;
