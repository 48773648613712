// no bottom navigation

const streamId = localStorage.getItem('streamId');
const taskId = localStorage.getItem('taskId');
const HOME = '/';
const REGISTER = '/register_';
const EMAIL_VERIFICATION = '/email-verification_';
const LOGIN = '/login_';
const FORGET_PASSWORD = '/forget-password_';
const FORGET_PASSWORD_CODE_VERIFICATION = '/forget-password-code-verification_/:email';
const RESET_PASSWORD = '/reset-password_/:token';
const LIVE = '/live_/:taskId/:streamId';
const LIVE_PLAY = '/live-play_/:taskId';
const RECORDING_PLAY = '/recording_/';

const REFERRAL_CODE_INPUT = '/referral-code-input_';
// bottom navigation
const WELCOME = '/welcome';
const LIVE_FORM = '/live-form';
const REQUEST_VIDEO_FORM = '/request-video-form';
const PROFILE = '/profile';
const REFER_AND_EARN = '/refer';
const REFER_AND_EARN_TRANSACTION = '/refer/transaction';
const STREAMER_PROFILE = '/profile/:id';
const DISCOVER = '/discover/:discoverType';
const LIVE_ALL = '/live-all';
const SUBMIT_PROPOSAL = '/submit-proposal/:taskId';
const REQUEST_VIDEO_TO_STREAMER = '/:streamerId/request';
const MY_TASK = '/my-task/:taskType';
const MY_REQUEST = '/my-request/:requestType';
const PERSONAL_DETAILS = '/personal-details';
const EDIT_PROFILE = '/profile/edit';
const SCHEDULE_LIVE = '/my-task/schedule-live/:proposalId/:taskId';
const MY_REQUEST_PROPOSALS = '/my-request/proposals/:taskId';
const MY_REQUEST_PROPOSAL = '/my-request/proposals/proposal/:proposalId/:taskId';
const CHAT_USER = '/my-task/chat/:taskId';
const CHAT_CREATOR = '/my-request/chat/:taskId';
const RESCHEDULE = '/reschedule/:proposalId/:taskId';
const TOP_REQUEST = '/top-request';
const TOP_STREAMERS = '/top-streamers';
const MY_VIDEOS = '/my-videos';
const WALLET = '/welcome/wallet';
const NOTIFICATIONS = '/welcome/notifications';
const MESSAGES = '/welcome/messages';
const USER_MESSAGES = '/welcome/messages/:userId';
const WALLET_ADD_MONEY = '/welcome/wallet/add-money';
const WALLET_WITHDRAW_MONEY = '/welcome/wallet/withdraw-money';
const WALLET_MANAGE = '/welcome/wallet/mange';
const ADD_BANK_ACCOUNT = '/welcome/wallet/add-bank-account/:amount';
const EDIT_BANK_ACCOUNT = '/welcome/wallet/edit-bank-account/:id';
const FEEDBACK = '/profile/feedback';
const POPULAR_VIDEOS = '/welcome/popular-videos';

const ROUTES = {
  HOME,
  LOGIN,
  REGISTER,
  EMAIL_VERIFICATION,
  FORGET_PASSWORD,
  FORGET_PASSWORD_CODE_VERIFICATION,
  RESET_PASSWORD,
  WELCOME,
  LIVE,
  LIVE_FORM,
  REQUEST_VIDEO_FORM,
  REQUEST_VIDEO_TO_STREAMER,
  LIVE_PLAY,
  PROFILE,
  REFER_AND_EARN,
  REFER_AND_EARN_TRANSACTION,
  STREAMER_PROFILE,
  DISCOVER,
  LIVE_ALL,
  SUBMIT_PROPOSAL,
  MY_TASK,
  MY_REQUEST,
  PERSONAL_DETAILS,
  EDIT_PROFILE,
  SCHEDULE_LIVE,
  MY_REQUEST_PROPOSALS,
  MY_REQUEST_PROPOSAL,
  CHAT_USER,
  CHAT_CREATOR,
  RESCHEDULE,
  TOP_REQUEST,
  TOP_STREAMERS,
  MY_VIDEOS,
  WALLET,
  NOTIFICATIONS,
  MESSAGES,
  USER_MESSAGES,
  WALLET_ADD_MONEY,
  WALLET_WITHDRAW_MONEY,
  WALLET_MANAGE,
  ADD_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  RECORDING_PLAY,
  FEEDBACK,
  REFERRAL_CODE_INPUT,
  POPULAR_VIDEOS,
};

export default ROUTES;
