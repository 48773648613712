import React from 'react';

function CoinIcon({ size }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' viewBox={`0 0 16 16`} >
      <path opacity="0.4" d="M7.4925 13.485C10.8021 13.485 13.485 10.8021 13.485 7.4925C13.485 4.18293 10.8021 1.5 7.4925 1.5C4.18293 1.5 1.5 4.18293 1.5 7.4925C1.5 10.8021 4.18293 13.485 7.4925 13.485Z" fill="#FDCC0A"/>
    <path d="M16.4771 11.995C16.4771 14.47 14.4671 16.48 11.9921 16.48C10.4621 16.48 9.11957 15.715 8.30957 14.5525C11.5796 14.185 14.1821 11.5825 14.5496 8.3125C15.7121 9.1225 16.4771 10.465 16.4771 11.995Z" fill="#FDCC0A"/>
    <path d="M8.5875 7.27969L6.7875 6.64969C6.6075 6.58969 6.57 6.57469 6.57 6.31219C6.57 6.11719 6.705 5.95969 6.8775 5.95969H8.0025C8.2425 5.95969 8.4375 6.17719 8.4375 6.44719C8.4375 6.75469 8.6925 7.00969 9 7.00969C9.3075 7.00969 9.5625 6.75469 9.5625 6.44719C9.5625 5.58469 8.895 4.87969 8.0625 4.84219V4.80469C8.0625 4.49719 7.8075 4.24219 7.5 4.24219C7.1925 4.24219 6.9375 4.48969 6.9375 4.80469V4.84219H6.87C6.0825 4.84219 5.4375 5.50219 5.4375 6.31969C5.4375 7.03219 5.7525 7.48969 6.405 7.71469L8.2125 8.34469C8.3925 8.40469 8.43 8.41969 8.43 8.68219C8.43 8.87719 8.295 9.03469 8.1225 9.03469H6.9975C6.7575 9.03469 6.5625 8.81719 6.5625 8.54719C6.5625 8.23969 6.3075 7.98469 6 7.98469C5.6925 7.98469 5.4375 8.23969 5.4375 8.54719C5.4375 9.40969 6.105 10.1147 6.9375 10.1522V10.1972C6.9375 10.5047 7.1925 10.7597 7.5 10.7597C7.8075 10.7597 8.0625 10.5047 8.0625 10.1972V10.1597H8.13C8.9175 10.1597 9.5625 9.49969 9.5625 8.68219C9.5625 7.96969 9.24 7.51219 8.5875 7.27969Z" fill="#FDCC0A"/>
    </svg>
  );
}

export default CoinIcon;
