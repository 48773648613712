import React, { useEffect } from 'react';
import { GlobalImages } from '../../utils/GlobalImports';
import Routes from '../../routes';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Loading from '../../components/Loading/Loading';
import SuccessModal from '../../components/SuccessModal/SuccessModal';
import Divider from '@mui/material/Divider';
import Google from '../../assets/icons/google.svg';
import RegisterForm from './RegisterForm';
import LeftArrowIcon from '../../assets/icons/LeftArrowIcon';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import { useForm, useWatch } from 'react-hook-form';
import handleGoogleAuth, { newUserDbEntry } from '../../utils/handleGoogleAuth';
import ROUTES from '../../routes';
import { useAuthUser } from '../../contexts/AuthContext';

const RegisterPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAllowLocationModal, setIsAllowLocationModal] = React.useState(false);
  const [isShowRegisterForm, setIsShowRegisterForm] = React.useState(false);

  useEffect(() => {
    const checkUser = async () => {
      if (user) {
        await newUserDbEntry(user, navigate);
        navigate(ROUTES.WELCOME);
      }
    };

    checkUser();
  }, [user, navigate]);
  // const { coordinates, error: locationError } = useGeoLocation({ setIsAllowLocationModal: setIsAllowLocationModal });
  // const { data: location } = useGetCountryFromLatLon(coordinates);
  const [openTermsModal, setOpenTermsModal] = React.useState(false);
  const { control, handleSubmit } = useForm();
  const [terms] = useWatch({ control, name: ['terms'] });

  const onSubmit = async (value) => {
    console.log(value);
    if (value.terms) {
      await handleGoogleAuth(setIsLoading, setIsAllowLocationModal, navigate);
    }
  };

  if (isLoading) return <Loading />;
  return (
    <>
      {isShowRegisterForm ? (
        <IconButton
          onClick={() => setIsShowRegisterForm(false)}
          sx={{
            justifyContent: 'flex-start',
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 10,
          }}
        >
          <LeftArrowIcon />
        </IconButton>
      ) : null}
      <Stack
        spacing={3}
        sx={{
          position: 'relative',
          minHeight: '100dvh',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box display='flex' justifyContent='center'>
          <img src={GlobalImages.BunpunLogo} alt='bunpun-logo' className='bp-logo' />
        </Box>
        <Stack spacing={1} alignItems='center'>
          <Typography variant='h3_dark'>Welcome aboard!</Typography>
          <Typography variant='h5'>Join the bunpun community today!</Typography>
        </Stack>
        {isShowRegisterForm ? (
          <RegisterForm setIsLoading={setIsLoading} />
        ) : (
          <Stack spacing={2.5} pt={2} width='100%'>
            <Button
              variant='outlined'
              size='large'
              fullWidth
              startIcon={<img src={Google} alt='Google_img' />}
              onClick={() => setOpenTermsModal(true)}
            >
              Sign up with Google
            </Button>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography mx={2}> or </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Box>
            <Button variant='contained' size='large' fullWidth onClick={() => setIsShowRegisterForm(true)}>
              Continue with email
            </Button>
          </Stack>
        )}

        <Typography variant='h5_semibold' align='center' pt={5}>
          Have an account?{' '}
          <Typography variant='h5_dark' component={Link} to={Routes.LOGIN}>
            Sign In
          </Typography>
        </Typography>
      </Stack>
      <CustomDialog open={openTermsModal} handleClose={() => setOpenTermsModal(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} alignItems={'center'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomCheckbox
                name={'terms'}
                control={control}
                rules={{ required: 'Please accept terms and conditions' }}
              />
              <Typography variant={'h5_semibold'}>
                I agree with bunpun's{' '}
                <Link
                  href='https://www.bunpun.com/terms-of-service/'
                  target='_blank'
                  variant={'h5_dark'}
                  sx={{ textDecoration: 'underline', color: '#000' }}
                >
                  Terms of Service
                </Link>{' '}
                &{' '}
                <Link
                  href='https://www.bunpun.com/privacy-policy/'
                  target='_blank'
                  style={{
                    textDecoration: 'underline',
                  }}
                  variant={'h5_dark'}
                  sx={{ textDecoration: 'underline', color: '#000' }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
            <Button fullWidth variant='contained' type={'submit'} size='large' disabled={!terms}>
              Next
            </Button>
          </Stack>
        </form>
      </CustomDialog>
      <SuccessModal
        open={isAllowLocationModal}
        handleClose={() => setIsAllowLocationModal(false)}
        onClick={() => {
          setIsAllowLocationModal(false);
        }}
        title='Location Access'
        message='Allow bunpun to access your location while you are using the app'
        btnText='Allow'
      />
    </>
  );
};

export default RegisterPage;
