import React, { useEffect, useState } from 'react'
import StreamRejoinModel from '../../../components/StreamRejoinModel/StreamRejoinModel';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Loading from '../../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../routes';
import api from '../../../config/api';
import useProfile from '../../../hooks/useProfile';

const getActiveStream = async () => {
  const { data } = await api.get(`/api/user/active-stream`);
  return data;
};

const ActiveStreamComponent = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [streamRejoinModal, setStreamRejoinModal] = useState(false);
    const { data: profile } = useProfile();
    const { data: activeStream, isLoading } = useQuery({
        queryKey: ['active-stream'],
        queryFn: () => getActiveStream(),
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });
    useEffect(() => {
        return () => {
          queryClient.removeQueries(['active-stream']);
        };
    }, [queryClient]);

    const taskId = activeStream?.data?._id;
    const streamId = activeStream?.data?.streamDetails?.liveStreamId;
    const name = profile?.data?.name;
    useEffect(() => {
        setStreamRejoinModal(!!activeStream?.data);
    }, [activeStream]);

  if (isLoading) <Loading/>;
  
  return (
    <StreamRejoinModel open={streamRejoinModal} name={name} onClick={() => {
        setStreamRejoinModal(false);
        navigate(ROUTES.LIVE.replace(':taskId', taskId).replace(':streamId', streamId));
        }
    }/>
  )
}

export default ActiveStreamComponent