import React from 'react';
import CustomDialog from '../CustomDialog/CustomDialog';
import { Button, Stack, Typography } from '@mui/material';
import InfoIconWithBackground from '../../assets/icons/InfoIconWithBackground';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import Routes from '../../routes';

const LoginModal = ({ open = false, handleClose }) => {
  const navigate = useNavigate();
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'}>
        <InfoIconWithBackground />
        <Typography variant='h5_dark'>Join the bunpun</Typography>
        <Stack spacing={1} alignItems={'center'} textAlign={'center'}>
          <Button fullWidth variant='contained' onClick={() => navigate(ROUTES.REGISTER)}>
            Sign up
          </Button>
          <Typography variant='h6_semibold' align='center'>
            Have an account?{' '}
            <Typography variant='h5_dark' component={Link} to={Routes.LOGIN}>
              Sign In
            </Typography>
          </Typography>
          {/*<Typography align='center' variant='h6'>*/}
          {/*  stream enjoy earn*/}
          {/*</Typography>*/}
        </Stack>
      </Stack>
    </CustomDialog>
  );
};

export default LoginModal;
