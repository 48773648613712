import { Stack, Typography } from '@mui/material';
import React from 'react';
import HeartIcon from '../../assets/icons/giftIcons/HeartIcon';
import CoinIcon from '../../assets/icons/giftIcons/CoinIcon';
import RankingIcon from '../../assets/icons/giftIcons/RankingIcon';
import { formatCount } from '../../utils/helpers';
import useGetWallet from '../../hooks/useGetWallet';

const Gifts = () => {
  const { data: wallet, isLoading } = useGetWallet();
  return (
    <>
      {!isLoading && (
        <Stack>
          <Typography variant='h6_semibold' sx={{ color: '#11111180' }}>
            Gifts Received
          </Typography>
          <Stack direction={'row'} gap={1}>
            <Stack flexDirection={'row'} gap={0.1} sx={{ alignItems: 'center' }}>
              <HeartIcon size={18} />
              <Typography variant='h7' >{formatCount(wallet?.data?.gifts?.hearts || 0)}</Typography>
            </Stack>
            <Stack flexDirection={'row'} gap={0.1} sx={{ alignItems: 'center' }}>
              <CoinIcon size={18} />
              <Typography variant='h7' >{formatCount(wallet?.data?.gifts?.coins || 0)}</Typography>
            </Stack>
            <Stack flexDirection={'row'} gap={0.1} sx={{ alignItems: 'center' }}>
              <RankingIcon size={18} />
              <Typography variant='h7' >{formatCount(wallet?.data?.gifts?.stars || 0)}</Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Gifts;
