import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, IconButton, InputAdornment, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SearchIcon from '../../assets/icons/SearchIcon';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -8,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const CustomTab = ({ tabs, initialActiveTab = 0, setSearchQuery }) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [searchValue, setSearchValue] = useState('');
  let sliderRef = useRef(null);
  useEffect(() => {
    console.log(initialActiveTab);
    sliderRef?.current?.swiper?.slideTo(initialActiveTab);
  }, [initialActiveTab]);
  useEffect(() => {
    sliderRef.current.addEventListener('swiperslidechange', (e) => {
      const [swiper] = e.detail;
      setActiveTab(swiper.activeIndex);
    });
  }, []);
  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setSearchQuery({ index: activeTab, query: value });
  };
  return (
    <Box>
      {setSearchQuery && (
        <Stack direction='row' my={1}>
          <TextField
            value={searchValue}
            onChange={onSearchChange}
            fullWidth
            size='small'
            placeholder={'Search'}
            sx={{
              '& input': {
                fontSize: '16px',
                fontWeight: 600,
                padding: '12px',
              },
            }}
            InputProps={{
              style: {
                borderRadius: '8px',
                borderColor: 'rgba(0, 0, 0, 0.1)',
                color: 'black',
              },
              startAdornment: (
                <InputAdornment position={'end'}>
                  <IconButton type={'submit'}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      )}
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          borderColor: 'divider',
          width: '100%',
          position: 'sticky',
          top: '50px',
          zIndex: 10,
          backgroundColor: '#fff',
          mb: 1,
        }}
      >
        {tabs.map((tab, index) => (
          <Button
            variant='h6_bold'
            onClick={() => {
              sliderRef?.current?.swiper?.slideTo(index);
              setActiveTab(index);
            }}
            key={tab.label}
            sx={{
              borderBottom: (theme) => activeTab === index && `3px solid ${theme.palette.primary.main}`,
              p: '12px 16px',
              borderRadius: 0,
            }}
            fullWidth
          >
            <StyledBadge badgeContent={tab?.totalCount} color='primary'>
              {tab.label}
            </StyledBadge>
          </Button>
        ))}
      </Box>
      <swiper-container
        ref={sliderRef}
        slides-per-view={1}
        style={{ width: '100%', height: tabs[activeTab]?.height }}
        space-between='10'
      >
        {tabs.map((tab) => (
          <swiper-slide style={{ width: '100%' }} key={tab.index}>
            {tab.panel}
          </swiper-slide>
        ))}
      </swiper-container>
    </Box>
  );
};

export default CustomTab;
