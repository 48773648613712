import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import ClockIcon from '../../../assets/icons/ClockIcon';
import ROUTES from '../../../routes';
import useInfiniteScrollUtils from '../../../utils/useInfiniteScrollUtils';
import { truncateTitle } from '../../../utils/helpers';

dayjs.extend(relativeTime);

const PendingTaskCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();
  const scheduleLiveLink = ROUTES.SCHEDULE_LIVE.replace(':proposalId', data?._id).replace(':taskId', data?.task?._id);
  const observer = useRef();
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  return (
    <Card sx={{ backgroundColor: '#f6f6f6', mb: 1 }} ref={index === totalCount - 1 ? lastElementRef : null}>
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Stack spacing={1}>
              <Typography variant={'h5_bold'}>{truncateTitle(data?.task?.title, 25)}</Typography>
              <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
                <ClockIcon />
                <Typography variant={'h6'}>{dayjs(data?.createdAt).fromNow()}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Avatar></Avatar>
              <Typography variant={'h6_bold'}>{truncateTitle(data?.task?.user?.name, 15)}</Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ borderRadius: 50, fontSize: '12px', color: '#000' }}
              onClick={() => navigate(scheduleLiveLink)}
            >
              Review
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PendingTaskCard;
