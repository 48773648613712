import { useEffect } from 'react';
import ROUTES from '../routes';
import { auth } from '../config/firebase';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const inactiveTimer = process.env.REACT_APP_INACTIVE_TIMER || 600;

const AutoLogOut = () => {
  let timer;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(
      () => {
        // clears any pending timer.
        resetTimer();
        // Listener clean up. Removes the existing event listener from the window
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        // logs out user
        logoutAction();
      },
      1000 * 60 * inactiveTimer,
    );
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    localStorage.removeItem('token');
    window.location.pathname = ROUTES.LOGIN;
  };

  return <></>;
};

// export default AutoLogOut;

export function StartTokenRefresh() {
  const refreshInterval = 50 * 60 * 1000; // 50 minutes in milliseconds

  // Run the refresh logic immediately and then every 50 minutes
  refreshToken(); // Initial call to refresh token

  // Set up the interval to refresh the token every 50 minutes
  setInterval(() => refreshToken(), refreshInterval);
}

// Function to refresh the ID token
async function refreshToken() {
  const user = auth.currentUser;

  if (user) {
    try {
      // Refresh the ID token
      const idToken = await user.getIdToken(true); // `true` forces token refresh
      // Store the new token in localStorage
      localStorage.setItem('token', idToken);
      console.log('ID Success');
    } catch (error) {
      console.error('Error refreshing ID token:', error);
      localStorage.removeItem('token');
      window.location.href = ROUTES.LOGIN;
    }
  } else {
    console.error('No user is signed in.');
  }
}
