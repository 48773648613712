import React from 'react';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import { IconButton, Stack, Typography } from '@mui/material';
import HeartIcon from '../../assets/icons/giftIcons/HeartIcon';
import CoinIcon from '../../assets/icons/giftIcons/CoinIcon';
import RankingIcon from '../../assets/icons/giftIcons/RankingIcon';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../config/api';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

const sendGift = async ({ streamerId, giftType, taskId }) => {
  await api.post('api/stream/gift', { streamerId, [giftType]: 1, taskId });
};

const SendGiftDrawer = ({ streamer, showGiftDrawer, setShowGiftDrawer, taskId, streamId, onSendGift }) => {
  const queryClient = useQueryClient();
  const [selectedGift, setSelectedGift] = React.useState({ streamerId: '', giftType: '' });
  const sendGiftMutate = useMutation({
    mutationFn: ({ streamerId, giftType, taskId }) => sendGift({ streamerId, giftType, taskId }),
    onError: (error) => {
      console.log(error);
      toast.error('Failed to send gift');
    },
    onSuccess: async () => {
      toast.success('Gift sent successfully');
    },
  });

  // const onSendGift = async () => {
  //   try {
  //     await sendGiftMutate.mutateAsync({ ...selectedGift, taskId });
  //     socket.emit('send_gift', { room: streamId });
  //     await queryClient.invalidateQueries(['task', taskId]);
  //     setShowGiftDrawer(false);
  //     setSelectedGift({ streamerId: '', giftType: '' });
  //   } catch (error) {
  //     // Error handling is already done in the mutation's onError
  //     console.error('Failed to send gift:', error);
  //   }
  // };

  return (
    <BottomDrawer bottomDrawerState={showGiftDrawer} setBottomDrawerState={setShowGiftDrawer}>
      <Stack spacing={3} sx={{ maxHeight: '20rem', overflowY: 'auto' }}>
        <Typography variant='h5_bold' sx={{ textAlign: 'center' }}>
          Send a Gift
        </Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <IconButton
            sx={{ backgroundColor: selectedGift.giftType === 'hearts' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
            onClick={() => setSelectedGift({ streamerId: streamer?._id, giftType: 'hearts' })}
          >
            <HeartIcon size={60} />
          </IconButton>
          <IconButton
            sx={{ backgroundColor: selectedGift.giftType === 'coins' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
            onClick={() => setSelectedGift({ streamerId: streamer?._id, giftType: 'coins' })}
          >
            <CoinIcon size={60} />
          </IconButton>
          <IconButton
            sx={{ backgroundColor: selectedGift.giftType === 'stars' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
            onClick={() => setSelectedGift({ streamerId: streamer?._id, giftType: 'stars' })}
          >
            <RankingIcon size={60} />
          </IconButton>
        </Stack>
        <Button
          variant='contained'
          onClick={() => {
            onSendGift(selectedGift);
            setShowGiftDrawer(false);
          }}
          disabled={selectedGift.giftType === ''}
        >
          Send {selectedGift.giftType}
        </Button>
      </Stack>
    </BottomDrawer>
  );
};

export default SendGiftDrawer;
