import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { clearInterval, setInterval } from 'worker-timers';
import ClockIcon from '../../assets/icons/ClockIcon';
import { toast, Bounce } from 'react-toastify';
import { set } from 'react-hook-form';

const CountdownTimer = ({ totalDuration, streamedDuration, endLiveStream }) => {
  const [timeDuration, setTimeDuration] = useState(totalDuration.current);
  const [countdownText, setCountdownText] = useState({ min: '0', sec: '0' });
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      setTimeDuration((prev) => prev - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const minutes = timeDuration / 60000;
    const seconds = (timeDuration % 60000) / 1000;
    setCountdownText({
      min: `${minutes < 10 ? '0' : ''}${Math.trunc(minutes)}`,
      sec: `${seconds < 10 ? '0' : ''}${Math.trunc(seconds)}`,
    });

    if (streamedDuration.current.flag) {
      setTimeDuration(totalDuration.current);
      streamedDuration.current.flag = false;
    }

    streamedDuration.current.time += 1000;
  }, [timeDuration]);

  if (totalDuration.current && timeDuration === 60000 && !toastShown) {
    toast.warn('You have 1 min remaining to stream', { position: 'top-center', autoClose: 5000, transition: Bounce });
    setToastShown(true);
  }

  if (totalDuration.current && timeDuration <= 3000 && toastShown) {
    setToastShown(false);
    toast.error('We are Closing your Stream!', { position: 'top-center', autoClose: 3000, transition: Bounce });
    setTimeout(() => {
      endLiveStream();
    }, 2000);
  }

  console.log('totalDuration', totalDuration.current, timeDuration);

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#5F5D5D80',
        color: '#fff',
        borderRadius: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 70,
      }}
      gap={1}
    >
      <ClockIcon color={'white'} />
      <Typography variant='caption_bold'>
        {countdownText.min}:{countdownText.sec}
      </Typography>
    </Box>
  );
};

export default CountdownTimer;
