import api from '../config/api';
import { useQuery } from '@tanstack/react-query';
import isGuestUser from './isGuestUser';

const getWallet = async () => {
  if (isGuestUser()) return { data: null };
  const { data } = await api.get('/api/user/wallet');
  return data;
};
const useGetWallet = () => {
  return useQuery({
    queryKey: ['wallet'],
    queryFn: getWallet,
  });
};
export default useGetWallet;
