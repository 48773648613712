import React, { useEffect, useRef, useState, useMemo } from 'react';
import LikeIcon from '../../assets/icons/LikeIcon';
import MessageIcon from '../../assets/icons/MessageIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';
import api from '../../config/api';
import { useForm, useWatch } from 'react-hook-form';
import useProfile from '../../hooks/useProfile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CustomTextField from '../CustomTextField/CustomTextField';
import SendIcon from '../../assets/icons/SendIcon';
import ChatBox from '../ChatBox/ChatBox';
import SocialShare from '../SocialShare/SocialShare';
import useGetChats from '../../hooks/useGetChats';
import useSendLike from '../../hooks/useSendLike';
import LikeCommentShareBox from '../LikeCommentShareComponent/LikeCommentShareBox';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';
import useIsUserLiked from '../../hooks/useIsUserLiked';
import LikeIconFilled from '../../assets/icons/LikeIconFilled';
import GiftIcon from '../../assets/icons/GiftIcon';
import isGuestUser from '../../hooks/isGuestUser';
import SendGiftDrawer from '../SendGiftDrawer/SendGiftDrawer';
import LoginModal from '../LoginModal/Login';
import useSocket from '../../hooks/useSocket';
import { useSelector, useDispatch } from 'react-redux';
import {
  setLikesCount,
  setChatsCount,
  setGiftsCount,
  addChat,
  setChats,
  resetState,
  setGifts,
} from '../../store/likesChatsGiftsSlice';
import { calculateTotalGifts } from '../../transformers/liveStreamTransformer';
// import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
// import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import MuteIcon from '../../assets/icons/MuteIcon';
import UnMuteIcon from '../../assets/icons/UnMuteIcon';

const PlayerSkin = ({
  taskId,
  streamId,
  task,
  isViewer = false,
  socket,
  sendLike,
  sendGift,
  sendChat,
  setMuted,
  muted,
}) => {
  const socialShareRef = useRef(null);
  const chatListRef = useRef(null);
  const { handleSubmit, control, reset } = useForm();
  const [messageWatch] = useWatch({ control, name: ['message'], disabled: true });
  const { data: profile } = useProfile();
  const { data, ...rest } = useGetChats(taskId);
  const chats = useInfiniteQueryReducedResult(data);
  const queryClient = useQueryClient();
  const { data: userIsLiked, refetch: refetchIsUserLiked } = useIsUserLiked(taskId);
  const [showGiftDrawer, setShowGiftDrawer] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const streamer = task?.data?.accepted?.user ?? task?.data?.user;
  const storeData = useSelector((state) => state.likesChatsGifts);
  const dispatch = useDispatch();

  const LIVE_STREAM_INVITE = process.env.REACT_APP_LIVE_STREAM_INVITE.replace('user', profile?.data?.name);

  console.log('storeData', storeData);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = 0; // Scroll to the top
    }

    if (chats) {
      dispatch(setChats(chats));
    }
  }, [chats]);

  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const handleSocialShareDialog = () => {
    setOpenShareDialog(!openShareDialog);
  };

  const onMessageSend = (value) => {
    sendChat({
      user: {
        _id: profile?.data?._id,
        name: profile?.data?.name,
        profile: {
          avatarUrl: profile?.data?.profile?.avatarUrl,
        },
      },
      room: streamId,
      text: value.message,
    });
    console.log(value.message);

    reset({ message: '' });
  };
  const onSendLike = () => {
    sendLike({
      room: streamId,
      userId: profile?.data?._id,
    });
    refetchIsUserLiked();
  };

  const onSendGift = (selectedGift) => {
    const { streamerId, giftType } = selectedGift;
    sendGift({
      room: streamId,
      streamerId,
      [giftType]: 1,
    });
    console.log('send-gifts', {
      room: streamId,
      streamerId,
      [giftType]: 1,
    });
  };

  let chatHeight =
    document.documentElement.scrollHeight - socialShareRef?.current?.getBoundingClientRect()?.bottom - 100;
  chatHeight = showGiftDrawer && isViewer ? chatHeight - 50 : chatHeight;

  useEffect(() => {
    messageWatch?.length > 0 && setShowGiftDrawer(false);
  }, [messageWatch?.length]);

  // console.log(messageWatch?.length === 0 && isViewer);
  // console.log('isViewer: ', isViewer);
  // console.log('messageWatch?.length: ', messageWatch?.length);
  // console.log('isGuestUser: ', isGuestUser());

  useEffect(() => {
    if (profile) {
      socket.emit('join_room', { room: streamId, user: profile?.data?._id });
    }
  }, [profile]);

  useEffect(() => {
    // setting the counts when the components loads
    dispatch(setLikesCount(task.data.likes));
    dispatch(setGifts(task.data.gifts));
    dispatch(setGiftsCount(calculateTotalGifts(task.data.gifts)));
    dispatch(setChatsCount(task.data.chats));
  }, []);

  const endAdornment = useMemo(() => {
    if (isViewer && (!messageWatch || messageWatch.length === 0)) {
      return (
        <IconButton onClick={() => setShowGiftDrawer((prev) => !prev)}>
          <GiftIcon />
        </IconButton>
      );
    }
    return (
      <IconButton type='submit'>
        <SendIcon />
      </IconButton>
    );
  }, [isViewer, messageWatch]);

  const toggleMute = () => {
    setMuted((prev) => !prev); // Toggle the muted state
  };

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexDirection={'column'}
        width={'fit-content'}
        justifyContent={'center'}
        gap={1}
        sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translate(0, -50%)', zIndex: 11 }}
        ref={socialShareRef}
      >
        {isViewer && (
          <LikeCommentShareBox
            Icon={muted ? MuteIcon : UnMuteIcon}
            onClick={toggleMute}
            variant='light'
            value={<>&nbsp;</>}
          />
        )}
        <LikeCommentShareBox
          Icon={userIsLiked?.data?.isLiked ? LikeIconFilled : LikeIcon}
          value={storeData.likesCount}
          onClick={() => (isGuestUser() ? setOpenLoginModal(true) : onSendLike())}
        />
        <LikeCommentShareBox
          Icon={MessageIcon}
          value={storeData.chatsCount}
          onClick={() => isGuestUser() && setOpenLoginModal(true)}
        />
        <LikeCommentShareBox
          Icon={GiftIcon}
          variant='light'
          value={storeData.gifts.totalCount}
          onClick={() => isGuestUser() && setOpenLoginModal(true)}
        />
        <LikeCommentShareBox Icon={ShareIcon} onClick={handleSocialShareDialog} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: socialShareRef?.current?.getBoundingClientRect()?.bottom + 1,
          zIndex: 10,
          overflow: 'hidden',
          bottom: 0,
          width: '100%',
          padding: '16px 16px 25px 16px',
          background: 'linear-gradient(to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0))',
        }}
      >
        <Stack
          spacing={0.6}
          sx={{
            maxHeight: chatHeight,
            height: chatHeight,
            overflowY: 'scroll',
            scrollbarWidth: 'none',
          }}
          ref={chatListRef}
        >
          {storeData.chats?.map((chat, index) => (
            <ChatBox key={index} chat={chat} index={index} totalCount={storeData.chats?.length} {...rest} />
          ))}
        </Stack>
        {!isGuestUser() && (
          <form onSubmit={handleSubmit(onMessageSend)}>
            <CustomTextField
              name={'message'}
              control={control}
              type={'text'}
              endAdornment={<InputAdornment position={'end'}>{endAdornment}</InputAdornment>}
              backgroundColor='rgba(25, 25, 25, 0.4)'
              borderColor='rgba(255, 255, 255, 0.3)'
              color='#ffffff'
              borderRadius='8px'
            />
          </form>
        )}
      </Box>
      <SocialShare
        open={openShareDialog}
        setOpen={handleSocialShareDialog}
        url={window.location.href}
        text={LIVE_STREAM_INVITE}
      />
      <SendGiftDrawer
        showGiftDrawer={showGiftDrawer}
        setShowGiftDrawer={setShowGiftDrawer}
        streamer={streamer}
        taskId={taskId}
        streamId={streamId}
        onSendGift={onSendGift}
      />
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
};

export default PlayerSkin;
