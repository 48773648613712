import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, InputAdornment, Typography } from '@mui/material';
import ResetPasswordImage from '../../assets/images/ResetPasswordImage';
import Stack from '@mui/material/Stack';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import Button from '@mui/material/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Routes from '../../routes';
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon';
import HidePasswordIcon from '../../assets/icons/HidePasswordIcon';
import api from '../../config/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';

const updatePassword = async ({ token, password }) => {
  const { data } = await api.post('/api/public/update-password', { token, password });
  return data;
};

const ResetPasswordPage = (props) => {
  const { handleSubmit, control, getValues } = useForm();
  const { token } = useParams();
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  const [isShowPasswordAgain, setIsShowPasswordAgain] = React.useState(false);

  const { mutate: updatePasswordMutate, isPending } = useMutation({
    mutationFn: updatePassword,
    onSuccess: (data) => {
      toast.success('Password updated successfully');
      navigate(Routes.LOGIN);
    },
    onError: (error) => {
      toast.error('Session expired');
      // navigate(Routes.FORGET_PASSWORD);
    },
  });
  const handlePasswordReset = async (value) => {
    updatePasswordMutate({ token, password: value.password });
  };
  if (isPending) return <Loading />;
  return (
    <Box
      pt={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100dvh',
        width: '100%',
      }}
    >
      <Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ResetPasswordImage />
        </Box>
        <Stack spacing={1}>
          <Typography variant={'h3_dark'} align={'center'}>
            Reset Password?
          </Typography>
          <Typography variant={'h5'} align={'center'}>
            Set the new password for your account
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(handlePasswordReset)}>
          <Stack spacing={3} sx={{ mt: 3, textAlign: 'center' }}>
            <CustomTextField
              name={'password'}
              label={'Enter New Password'}
              control={control}
              rules={{ required: 'Password is required' }}
              type={isShowPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment
                  position={'end'}
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  sx={{ cursor: 'pointer' }}
                >
                  {isShowPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                </InputAdornment>
              }
            />
            <CustomTextField
              name={'new_password'}
              label={'Re-enter New Password'}
              control={control}
              rules={{
                required: 'Password is required',
                validate: (value) => {
                  if (value === getValues('password')) {
                    return true;
                  }
                  return 'Password does not match';
                },
              }}
              type={isShowPasswordAgain ? 'text' : 'password'}
              endAdornment={
                <InputAdornment
                  position={'end'}
                  onClick={() => setIsShowPasswordAgain(!isShowPasswordAgain)}
                  sx={{ cursor: 'pointer' }}
                >
                  {isShowPasswordAgain ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                </InputAdornment>
              }
            />
            <Button variant='contained' type='submit' size='large'>
              Next
            </Button>
          </Stack>
        </form>
      </Box>

      <Typography
        variant={'h5_dark'}
        component={Link}
        to={Routes.LOGIN}
        sx={{ margin: '20px 0px 40px 0px', width: '100%' }}
        textAlign={'center'}
      >
        Back to log in
      </Typography>
    </Box>
  );
};

export default ResetPasswordPage;
