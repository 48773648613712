import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../routes';
import CustomAvatar from '../../../../components/CustomAvatar/CustomAvatar';
import { truncateTitle } from '../../../../utils/helpers';

const TopStreamerCard = ({ data }) => {
  const navigate = useNavigate();
  const avatarUrl = data?.profile?.avatarUrl;
  return (
    <Box
      onClick={() => navigate(ROUTES.STREAMER_PROFILE.replace(':id', data._id))}
      sx={{ cursor: 'pointer', minWidth: '100px' }}
    >
      <Stack spacing={1.5} mr={3} alignItems={'center'}>
        <CustomAvatar avatarUrl={avatarUrl} name={data.name} size={80} />
        <Typography variant={'h6_bold'} align='center'>
          {truncateTitle(data.name, 15)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default TopStreamerCard;
