import React, { useState } from 'react';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import Stack from '@mui/material/Stack';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';
import { useForm } from 'react-hook-form';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { Box } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import Button from '@mui/material/Button';
import api from '../../config/api';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../routes';
import Loading from '../../components/Loading/Loading';
import SuccessModal from '../../components/SuccessModal/SuccessModal';

const AddBankAccountPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { handleSubmit, control, setError, getValues } = useForm();
  const { amount } = useParams();
  console.log(amount);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (value) => {
    const { accNo, re_accNo, code, accHolderName, accType, bankName } = value;
    if (accNo !== re_accNo) {
      setError('re_accNo', { message: 'Account Number does not match' });
    } else {
      try {
        // const { data } = await api.get(`/api/user/bank/ifsc/${IFSCCode}`);
        // const bankName = data.data.BANK;
        setIsLoading(true);
        const { data: bank } = await api.post('/api/user/bank', {
          accNo,
          code,
          accHolderName,
          accType,
          bankName,
        });

        if (typeof amount === 'number') {
          await api.post('/api/user/withdraw', { bank: bank.data._id, amount });
        }
        setIsLoading(false);

        setOpenModal(true);
      } catch (e) {
        setIsLoading(false);
        setError('IFSCCode', { message: 'Invalid IFSC Code' });
      }
    }
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <Box>
        <TopNavigation title={'Add Bank Account'} />
        <TopNavigationSpace />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <MenuHeader title={'Add Bank Account'} />
            <Stack spacing={1.5}>
              <CustomNumberField
                name={'accNo'}
                label={'Account Number'}
                control={control}
                rules={{
                  required: 'Account Number is required',
                  minLength: {
                    value: 10,
                    message: 'Account Number should be at least 10 digits',
                  },
                  maxLength: {
                    value: 17,
                    message: 'Account Number should not exceed 17 digits',
                  },
                }}
              />
              <CustomNumberField
                name={'re_accNo'}
                label={'Re-enter Account Number'}
                control={control}
                rules={{
                  required: 'Account Number is required',
                  validate: (value) => value === getValues('accNo') || 'Account numbers do not match',
                }}
              />
              <CustomTextField
                name={'code'}
                label={'Bank Code'}
                control={control}
                rules={{ required: 'Bank Code is required' }}
              />
              <CustomTextField
                name={'accHolderName'}
                label={'Account Holder Name'}
                control={control}
                rules={{
                  required: 'Account Holder Name is required',
                  pattern: {
                    value: /^[A-Za-z\s]+$/, // Allows only letters and spaces
                    message: 'Account Holder Name must contain only letters and spaces',
                  },
                }}
              />
              <CustomTextField
                name={'bankName'}
                label={'Bank Name'}
                control={control}
                rules={{
                  required: 'Bank Name is required',
                  pattern: {
                    value: /^[A-Za-z\s]+$/, // Allows only letters and spaces
                    message: 'Account Holder Name must contain only letters and spaces',
                  },
                }}
              />
            </Stack>
            <Button variant='contained' size='large' fullWidth rounded type='submit'>
              Next
            </Button>
          </Stack>
        </form>
      </Box>
      <SuccessModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={'Success'}
        message={'Bank Account Added Successfully'}
        btnText={'OK'}
        onClick={() => {
          setOpenModal(false);
          navigate(ROUTES.WALLET);
        }}
      />
    </>
  );
};

export default AddBankAccountPage;
