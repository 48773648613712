import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import LoginModal from '../../components/LoginModal/Login';
import isGuestUser from '../../hooks/isGuestUser';

const MenuHeader = ({ title, onClick, isViewAll = true }) => {
  const [openLoginModal, setOpenLoginModal] = useState(false);

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width='100%'>
        <Typography variant={'h4'}>{title}</Typography>
        {isViewAll && (
          <Stack
            spacing={0.5}
            direction={'row'}
            alignItems={'center'}
            onClick={() => {
              isGuestUser() ? setOpenLoginModal(true) : onClick();
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Typography>View all</Typography>
            <IconButton>
              <RightArrowIcon />
            </IconButton>
          </Stack>
        )}
      </Box>
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
};

export default MenuHeader;
