import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  browserVideoStatus: false,
  browserAudioStatus: false,
  browserWebRTCStatus: false,
};

export const liveStreamStatusSlice = createSlice({
  name: 'liveStreamStatus',
  initialState,
  reducers: {
    setBrowserVideoStatus(state, action) {
      state.browserVideoStatus = action.payload;
    },
    setBrowserAudioStatus(state, action) {
      state.browserAudioStatus = action.payload;
    },
    setBrowserWebRTCStatus(state, action) {
      state.browserWebRTCStatus = action.payload;
    },
  },
});

export const { setBrowserVideoStatus, setBrowserAudioStatus, setBrowserWebRTCStatus } = liveStreamStatusSlice.actions;

export default liveStreamStatusSlice.reducer;
