import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_BASE_URL_API;

const useSocket = () => {
  const [socket] = useState(io(URL, { autoConnect: false }));
  const [isConnected, setIsConnected] = useState(false);
  const [connectionError, setConnectionError] = useState(null);

  useEffect(() => {
    // Connect the socket
    socket.connect();

    // Handle socket connection events
    socket.on('connect', () => {
      console.log('Chat socket connected');
      setIsConnected(true);
      setConnectionError(null); // Reset error on successful connection
    });

    socket.on('connect_error', (error) => {
      console.error('Chat socket connection error:', error);
      setConnectionError(error.message); // Store the error message for user feedback
    });

    socket.on('disconnect', (reason) => {
      console.warn(`Chat socket disconnected due to: ${reason}`);
      setIsConnected(false);
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.info(`Chat reconnection attempt #${attempt}`);
    });

    socket.on('reconnect_failed', () => {
      console.error('Chat socket reconnection failed.');
      setConnectionError('Reconnection failed. Please try again later.');
    });

    // Cleanup on component unmount
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const connectSocket = () => {
    socket.connect();
  };

  const disconnectSocket = () => {
    socket.disconnect();
  };

  return {
    socket,
    isConnected,
    connectionError,
    connectSocket,
    disconnectSocket,
  };
};

export default useSocket;