import React, { useEffect, useState } from 'react';
import { setInterval } from 'worker-timers';
import ClockIcon from '../../assets/icons/ClockIcon';
import { Box, Typography } from '@mui/material';

const ClockTimer = ({ streamedDuration }) => {
  const [time, setTime] = useState(0);
  const [displayTime, setDisplayTime] = useState({ min: '0', sec: '0' });

  useEffect(() => {
    // Start the timer
    const interval = setInterval(() => {
      setTime((prev) => prev + 1000);
    }, 1000);

    // Clear the timer on cleanup
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const minutes = time / 1000 / 60;
    const seconds = (time % 60000) / 1000;
    setDisplayTime({
      min: `${minutes < 10 ? '0' : ''}${Math.trunc(minutes)}`,
      sec: `${seconds < 10 ? '0' : ''}${Math.trunc(seconds)}`,
    });

    if (streamedDuration.current.flag) {
      setTime(streamedDuration.current.time);
      streamedDuration.current.flag = false;
    } else streamedDuration.current.time = time;
  }, [time]);

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#5F5D5D80',
        color: '#fff',
        borderRadius: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 70,
      }}
      gap={1}
    >
      <ClockIcon color={'white'} />
      <Typography variant='caption_bold'>
        {displayTime.min}:{displayTime.sec}
      </Typography>
    </Box>
  );
};

export default ClockTimer;
