import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import useGetWallet from '../../hooks/useGetWallet';
import { useForm, useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { Box, InputAdornment, Typography } from '@mui/material';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';
import WalletBalance from '../../components/WalletBalance/WalletBalance';
import Loading from '../../components/Loading/Loading';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import api from '../../config/api';
import InfoModal from '../../components/InfoModal/InfoModal';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useSetting from '../../hooks/useSetting';
import currencyFormat from '../../utils/currencyFormat';
import { TRANSACTION_RESPONSE, TRANSACTION_TYPE } from '../../utils/constant';

dayjs.extend(customParseFormat);

const getWithdrawalHistory = async () => {
  const { data } = await api.get(`/api/user/withdraw?page=1&size=5`);
  return data;
};

const WithdrawalHistoryBox = ({ data }) => {
  const { status, txnType, updatedAt, amount } = data;
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Stack spacing={0.5}>
        <Typography variant='h6_bold'>{txnType === TRANSACTION_TYPE.REFUND ? 'Refund' : 'Withdrawal'}</Typography>
        <Typography variant='h6'>Requested on: {dayjs(updatedAt).format('DD MMM YYYY, hh:mm A')}</Typography>
      </Stack>
      <Stack>
        <Typography
          variant='h6_dark'
          color={(theme) => {
            if (status === TRANSACTION_RESPONSE.PENDING) return theme.palette.warning.main;
            else if (txnType === TRANSACTION_TYPE.DEBIT) return theme.palette.error.main;
            else if (status === TRANSACTION_RESPONSE.SUCCESS || status === TRANSACTION_TYPE.REWARD)
              return theme.palette.success.main;
          }}
        >
          {txnType === TRANSACTION_TYPE.CREDIT || txnType === TRANSACTION_TYPE.REFUND ? '+' : '-'}
          {currencyFormat(Math.abs(amount))}
        </Typography>
        <Typography variant='h6'>
          {status === TRANSACTION_RESPONSE.SUCCESS && 'Success'}
          {status === TRANSACTION_RESPONSE.PENDING && 'Pending'}
          {status === TRANSACTION_RESPONSE.REJECT && 'Rejected'}
        </Typography>
      </Stack>
    </Box>
  );
};

const WithdrawMoneyForm = ({ setAmount }) => {
  const { data: wallet, isLoading } = useGetWallet();
  const { control, handleSubmit, setError, clearErrors } = useForm();
  const [isWithdrawal, setIsWithdrawal] = React.useState(false);
  const [isWithdrawalError, setIsWithdrawalError] = React.useState(false);
  const [amount] = useWatch({ control, name: ['amount'] });
  const { data: setting } = useSetting();
  const minWithdrawalAmount = setting?.data?.minWithdraw;
  const amountInt = parseInt(amount);
  useEffect(() => {
    if (amountInt < minWithdrawalAmount - 1) {
      console.log('amountInt < minWithdrawalAmount - 1', amountInt, minWithdrawalAmount - 1);
      setError('amount', {
        type: 'custom',
        message: `Minimum amount to withdraw is ${currencyFormat(minWithdrawalAmount)}`,
      });
      setIsWithdrawal(false);
    } else if (amountInt > wallet?.data?.totalBalance) {
      console.log('amountInt > wallet?.data?.totalBalance', amountInt, wallet?.data?.totalBalance);
      setError('amount', {
        type: 'custom',
        message: `Insufficient Balance`,
      });
      setIsWithdrawal(false);
    } else if (amountInt >= minWithdrawalAmount) {
      console.log('amountInt > minWithdrawalAmount', amountInt, minWithdrawalAmount);
      clearErrors('amount');
      setIsWithdrawal(true);
    }
  }, [amountInt, clearErrors, minWithdrawalAmount, setError, wallet?.data?.totalBalance]);
  useEffect(() => {
    if (wallet?.data?.totalBalance < amountInt) {
      setIsWithdrawal(false);
    }
  }, [amountInt, wallet?.data?.totalBalance]);
  const onSubmit = async (value) => {
    try {
      const { data: withdrawStatus } = await api.get('/api/user/withdraw/pending');
      if (withdrawStatus?.data?.isPendingTxn) {
        setIsWithdrawalError(true);
      } else {
        setAmount(value.amount);
      }
    } catch (e) {}
  };

  const { data: withdrawalHistory } = useQuery({
    queryKey: ['withdrawalHistory'],
    queryFn: getWithdrawalHistory,
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <Loading />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={2}>
          <WalletBalance wallet={wallet} />
          <Stack spacing={1.5}>
            <CustomNumberField
              name='amount'
              label={`Enter Amount to Withdraw`}
              control={control}
              startAdornment={
                <InputAdornment position='start'>
                  <AttachMoneyIcon />
                </InputAdornment>
              }
              rules={{
                required: 'Enter Amount',
              }}
            />
          </Stack>
          <Button variant='contained' type='submit' size='large' rounded fullWidth disabled={!isWithdrawal}>
            Next
          </Button>
          <Typography variant='h5_dark'>History</Typography>
          {withdrawalHistory?.data?.map((withdraw) => (
            <WithdrawalHistoryBox key={withdraw._id} data={withdraw} />
          ))}
        </Stack>
      </form>
      <InfoModal
        open={isWithdrawalError}
        handleClose={() => setIsWithdrawalError(false)}
        title={'Previous Withdrawal Pending.'}
        message={'You have already placed withdrawal request. Please wait until it gets completed.'}
      />
    </>
  );
};

export default WithdrawMoneyForm;
