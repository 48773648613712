import React from 'react';

function checkPlaybackURL(url, maxRetries = 10) {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let retryCount = 0;

  const checkURL = async () => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.status === 200) {
        return true;
      }

      // If status is not 200 and we haven't exceeded max retries
      if (retryCount < maxRetries) {
        retryCount++;
        await delay(10000); // Wait 10 seconds
        return checkURL(); // Recursive retry
      }

      return false;
    } catch (error) {
      if (retryCount < maxRetries) {
        retryCount++;
        await delay(10000); // Wait 10 seconds
        return checkURL(); // Recursive retry
      }
      return false;
    }
  };

  return checkURL();
}

export default checkPlaybackURL;
