function checkPlaybackURL(url, maxRetries = 10, retryInterval = 10000) {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let retryCount = 0;

  const checkURL = async () => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 5000); // Timeout fetch after 5 seconds

    try {
      const response = await fetch(url, { method: 'HEAD', signal: controller.signal });
      clearTimeout(timeout);

      if (response.status === 200) {
        console.log('Playback URL is valid.');
        return true;
      }

      throw new Error(`Unexpected status code: ${response.status}`);
    } catch (error) {
      clearTimeout(timeout);

      if (retryCount < maxRetries) {
        retryCount++;
        console.warn(`Retrying (${retryCount}/${maxRetries})...`);
        await delay(retryInterval);
        return checkURL();
      }

      console.error('Max retries reached. Playback URL is invalid.');
      return false;
    }
  };

  return checkURL();
}

export default checkPlaybackURL;
