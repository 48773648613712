// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-slider .slick-list {
  padding-left: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/WelcomePage/components/TopStreamersComponent/TopStreamersComponent.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B","sourcesContent":[".custom-slider .slick-list {\n  padding-left: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
