import { Box, Button, IconButton, Stack, stepButtonClasses, Typography } from '@mui/material';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import useLiveStreamState from '../../hooks/useLiveStreamState';
import { useNavigate, useParams } from 'react-router-dom';
import useGetTask from '../../hooks/useGetTask';
import EyeIcon from '../../assets/icons/EyeIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from '../../components/Loading/Loading';
import ROUTES from '../../routes';
import { socket } from '../../config/socket';
import useProfile from '../../hooks/useProfile';
import PlayerSkin from '../../components/PlayerSkin/PlayerSkin';
import useGetViewers from '../../hooks/useGetViewers';
import LiveStreamStoppedPage from '../LiveStreamStoppedPage/LiveStreamStoppedPage';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import { LIVE_STREAM_STATUS } from '../../utils/constant';
import RatingModal from '../LiveStreamPage/RatingModal';
import PlayIcon from '../../assets/icons/PlayIcon';
import checkPlaybackURL from '../../utils/checkPlaybackURL';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import useSocket from '../../hooks/useSocket';
import { useDispatch } from 'react-redux';
import { setChatsCount } from '../../store/likesChatsGiftsSlice';

const LiveStreamPlayerPage = () => {
  const dispatch = useDispatch();
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [isShowBackground, setIsShowBackground] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const { taskId } = useParams();
  const navigate = useNavigate();

  const { data: task, isLoading } = useGetTask(taskId);
  const [streamId, setStreamId] = useState(null);
  const [playBackUrl, setPlayBackURl] = useState(null);
  const [isPlaybackUrlValid, setIsPlaybackUrlValid] = useState(false);
  const { socket, sendLike, sendGift, sendChat, disconnectSocket } = useSocket();

  const { data: profile } = useProfile();

  const { data } = useLiveStreamState(streamId);

  const memoizedData = useMemo(() => data, [data]);

  const browserVideoStatus = memoizedData?.task?.browserVideoStatus;
  const browserAudioStatus = memoizedData?.task?.browserAudioStatus;
  const browserWebRTCStatus = memoizedData?.task?.browserWebRTCStatus;

  const liveStreamStatus = memoizedData?.live_stream?.state;
  const viewers = memoizedData?.live_stream?.viewers;

  const isLiveStreamStarted = liveStreamStatus === LIVE_STREAM_STATUS.LIVE;

  useEffect(() => {
    if (data) {
      dispatch(setChatsCount(data.task.chats));
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setStreamId(task?.data?.streamDetails?.liveStreamId);
      setPlayBackURl(task?.data?.streamDetails?.HLSPlaybackURL);
    }
  }, [task]);

  useEffect(() => {
    if (
      liveStreamStatus === LIVE_STREAM_STATUS.STOPPED &&
      task?.data?.user?._id === profile?.data?._id &&
      !task?.data?.requester?.rating
    ) {
      setIsRatingModalOpen(true);
    }
  }, [liveStreamStatus, profile?.data?._id, task?.data?.user?._id]);
  const handlePlay = () => {
    console.log('handlePlay');
    setPlaying(!playing);
    setIsShowBackground(!isShowBackground);
  };
  const user = task?.data?.type === 'REQUEST' ? task?.data?.accepted?.user : task?.data?.user;
  console.log(user);
  const userName = user?.name;
  const avatarUrl = user?.profile?.avatarUrl;
  const streamerId = user?._id;

  useEffect(() => {
    if (playBackUrl) {
      checkPlaybackURL(playBackUrl).then((isValid) => {
        setIsPlaybackUrlValid(isValid);
      });
    }
  }, [playBackUrl]);

  if (isLoading || !liveStreamStatus || !isPlaybackUrlValid) {
    return <MyVideoLoading content='Please wait while we load the live stream...' />;
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          mx: { sm: '-24px', xs: '-16px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100dvh',
        }}
      >
        {isLiveStreamStarted ? (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9,
                cursor: isShowBackground ? 'pointer' : 'default',
              }}
              className={isShowBackground ? 'fadeIn' : 'fadeOut'}
              // onClick={handlePlay}
            >
              <Button variant='contained' sx={{ borderRadius: 50, p: 3 }}>
                <PlayIcon />
              </Button>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                width: '100%',
                px: 2,
                zIndex: 10,
              }}
            >
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <IconButton onClick={() => navigate(ROUTES.WELCOME)}>
                  <CancelIcon />
                </IconButton>
                <Box display={'flex'} alignItems={'center'} gap={1.5}>
                  <Box display={'flex'} alignItems={'center'} gap={0.5}>
                    <CustomAvatar name={userName} avatarUrl={avatarUrl} />
                    <Typography variant={'h6_dark'}>{task.data.user.name}</Typography>
                  </Box>
                  <Button
                    variant='contained'
                    sx={{ borderRadius: 50, fontSize: 9, p: '4px 12px' }}
                    onClick={() => navigate(ROUTES.REQUEST_VIDEO_TO_STREAMER.replace(':streamerId', streamerId))}
                  >
                    Request
                  </Button>
                </Box>
                <Stack
                  spacing={0.5}
                  direction={'row'}
                  sx={{
                    padding: '4px 8px 4px 8px',
                    backgroundColor: '#5F5D5D80',
                    color: '#fff',
                    borderRadius: '4px',
                  }}
                >
                  <EyeIcon />
                  <Typography variant={'caption_bold'}>{viewers}</Typography>
                </Stack>
              </Box>
              <Stack spacing={1} pt={2} sx={{ color: '#fff' }}>
                <Typography variant={'h5_semibold'}>{task.data.title}</Typography>
                <Typography variant={'h6'}>{task.data.description}</Typography>
              </Stack>
            </Box>
            <PlayerSkin
              taskId={taskId}
              streamId={streamId}
              task={task}
              isViewer={true}
              socket={socket}
              sendLike={sendLike}
              sendGift={sendGift}
              sendChat={sendChat}
              muted={muted}
              setMuted={setMuted}
            />
            {streamId && playBackUrl && (
              <>
                {browserAudioStatus && browserVideoStatus && browserWebRTCStatus ? (
                  <ReactPlayer
                    config={{
                      file: {
                        // forceHLS: true,
                        attributes: {
                          disablePictureInPicture: true,
                          controlsList: 'nodownload',
                          // playsInline: true,
                        },
                      },
                    }}
                    playsinline
                    url={playBackUrl}
                    playing={true}
                    volume={1}
                    controls={false}
                    muted={muted}
                    width='100%'
                    height='var(--player-height)'
                  />
                ) : (
                  <> Please wait the streamer will return shortly</>
                )}
              </>
            )}
          </>
        ) : (
          <LiveStreamStoppedPage />
        )}
      </Box>
      <RatingModal
        open={isRatingModalOpen}
        title={'How was your Live Stream?'}
        taskId={taskId}
        handleClose={() => setIsRatingModalOpen(false)}
      />
    </>
  );
};

export default LiveStreamPlayerPage;
