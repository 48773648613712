import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ClockIcon from '../../../assets/icons/ClockIcon';
import Button from '@mui/material/Button';
import ROUTES from '../../../routes';
import useInfiniteScrollUtils from '../../../utils/useInfiniteScrollUtils';
import CustomAvatar from '../../../components/CustomAvatar/CustomAvatar';
import { truncateTitle } from '../../../utils/helpers';

const AcceptedRequestCards = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();
  const observer = useRef();
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  const creatorChatLink = ROUTES.CHAT_CREATOR.replace(':taskId', data?._id);
  return (
    <Card sx={{ backgroundColor: '#f6f6f6', mb: 1 }} ref={index === totalCount - 1 ? lastElementRef : null}>
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Stack spacing={1}>
              <Typography variant='h5_bold'>{truncateTitle(data?.title, 25)}</Typography>
              <Stack spacing={0.5} direction='row' alignItems='center'>
                <ClockIcon />
                <Typography variant='h6'>{dayjs(data?.createdAt).fromNow()}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box display='flex' justifyContent='space-between'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='h6' sx={{ color: '#737373' }}>
                Assigned to:
              </Typography>
              <CustomAvatar avatarUrl={data?.accepted?.user?.profile?.avatarUrl} name={data?.accepted?.user?.name} />
              <Typography variant='h6_bold'>{truncateTitle(data?.accepted?.user?.name, 15)}</Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ borderRadius: 50, fontSize: '12px' }}
              onClick={() => navigate(creatorChatLink)}
              // disabled={dayjs(data?.requestAt).add(data?.duration, 'm').isBefore(Date.now())}
            >
              {/* {dayjs(data?.requestAt).add(data?.duration, 'm').isBefore(Date.now()) ? 'Request Expired' : 'View'} */}
              {'View'}
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AcceptedRequestCards;
