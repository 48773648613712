import { configureStore } from '@reduxjs/toolkit';
import publishSettingsSlice from './publishSettingSlice';
import webrtcPublishSlice from './webrtcPublishSlice';
import liveStreamStatusSlice from './liveStreamStatusSlice';
import likesChatsGiftsSlice from './likesChatsGiftsSlice';

export const store = configureStore({
  reducer: {
    publishSettings: publishSettingsSlice,
    webrtcPublish: webrtcPublishSlice,
    liveStreamStatus: liveStreamStatusSlice,
    likesChatsGifts: likesChatsGiftsSlice,
  },
});
