import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getPopularVideos = async () => {
  try {
    const { data } = await api.get('/api/user/popular-videos?page=1&size=5');
    if (data) return data;
  } catch (error) {
    console.error(error);
  }
};

const useGetPopularVideos = () => {
  return useQuery({
    queryKey: ['popularVideos'],
    queryFn: getPopularVideos,
  });
};

export default useGetPopularVideos;
