import React, { useEffect } from 'react';
import { GlobalImages } from '../../utils/GlobalImports';
import Google from '../../assets/icons/google.svg';
import './style.css';
import { Box, InputAdornment, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ROUTES from '../../routes';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon';
import HidePasswordIcon from '../../assets/icons/HidePasswordIcon';
import SuccessModal from '../../components/SuccessModal/SuccessModal';
import handleGoogleAuth, { newUserDbEntry } from '../../utils/handleGoogleAuth';
import ReactGA from 'react-ga4';
import { useAuthUser } from '../../contexts/AuthContext';

function LoginPage() {
  const { handleSubmit, control } = useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  const [isAllowLocationModal, setIsAllowLocationModal] = React.useState(false);
  const navigate = useNavigate();
  const { user } = useAuthUser();
  // const { coordinates, error: locationError } = useGeoLocation({ setIsAllowLocationModal: setIsAllowLocationModal });
  // const { data: location } = useGetCountryFromLatLon(coordinates);

  useEffect(() => {
    const checkUser = async () => {
      if (user) {
        await newUserDbEntry(user, navigate);
        navigate(ROUTES.WELCOME);
      }
    };

    checkUser();
  }, [user, navigate]);

  const onSubmit = async (value) => {
    setIsLoading(true);
    console.log(value);
    const { email, password } = value;
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log(userCredential);
      const token = await auth.currentUser.getIdToken();
      localStorage.setItem('token', token);
      localStorage.setItem('isNewUser', 'false');
      setIsLoading(false);
      navigate(ROUTES.WELCOME);
      ReactGA.event({
        category: 'Login',
        action: 'Login',
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error('Invalid email or password');
    }
  };

  if (isLoading) return <Loading />;
  return (
    <>
      <Stack sx={{ position: 'relative', height: '100dvh', justifyContent: 'center' }}>
        <Box display={'flex'} justifyContent={'center'}>
          <img src={GlobalImages.BunpunLogo} alt='bunpun-logo' className='bp-logo' />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} mt={3}>
            <Button
              variant='outlined'
              size='large'
              fullWidth
              startIcon={<img src={Google} alt='Google_img' />}
              onClick={() => handleGoogleAuth(setIsLoading, setIsAllowLocationModal, navigate)}
            >
              Sign in with Google
            </Button>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography mx={2}> or </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Box>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <CustomTextField
                name={'email'}
                label={'Email Address'}
                control={control}
                rules={{ required: 'Email is required' }}
                type={'email'}
              />
              <CustomTextField
                name={'password'}
                label={'Password'}
                control={control}
                rules={{ required: 'Password is required' }}
                type={isShowPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment
                    position={'end'}
                    onClick={() => setIsShowPassword(!isShowPassword)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {isShowPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                  </InputAdornment>
                }
              />
              <Typography
                variant='h5_dark'
                component={Link}
                to={ROUTES.FORGET_PASSWORD}
                style={{ marginLeft: 'auto', width: 'fit-content' }}
              >
                Forgot password?
              </Typography>
            </Stack>
            <Button variant='contained' type={'submit'} size='large'>
              Sign In
            </Button>
            <Typography variant='h5_semibold' align={'center'} pt={5} textAlign='center'>
              Don’t have an account?
              <Typography variant='h5_dark' component={Link} to={ROUTES.REGISTER}>
                {' '}
                Sign Up
              </Typography>
            </Typography>
          </Stack>
        </form>
      </Stack>

      <SuccessModal
        open={isAllowLocationModal}
        handleClose={() => setIsAllowLocationModal(false)}
        onClick={() => {
          setIsAllowLocationModal(false);
        }}
        title={'Location Access'}
        message={'Allow bunpun to access your location while you are using the app'}
        btnText={'Allow'}
      />
    </>
  );
}

export default LoginPage;
