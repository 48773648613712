import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import './liveStreamPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import api from '../../config/api';
import startPublish from './newPublish';
import stopPublish from './stopPublish';
import EyeIcon from '../../assets/icons/EyeIcon';
import PlayerSkin from '../../components/PlayerSkin/PlayerSkin';
import useGetViewers from '../../hooks/useGetViewers';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import LiveStreamStoppedPage from '../LiveStreamStoppedPage/LiveStreamStoppedPage';
import EndLiveStreamModal from './EndLiveStreamModal';
import ROUTES from '../../routes';
import Webcam from 'react-webcam';
import { LIVE_STREAM_STATUS, STREAM_TYPE } from '../../utils/constant';
import ClockTimer from '../../components/ClockTimer/ClockTimer';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import useGetTask from '../../hooks/useGetTask';
import { toast } from 'react-toastify';
import useLiveStreamState from '../../hooks/useLiveStreamState';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPublishApplicationName,
  setPublishAudioTrackDeviceId,
  setPublishSignalingURL,
  setPublishStreamName,
  setPublishVideoTrack1DeviceId,
} from '../../store/publishSettingSlice';
import useSocket from '../../hooks/useSocket';
import {
  setBrowserAudioStatus,
  setBrowserVideoStatus,
  setBrowserWebRTCStatus,
} from '../../store/liveStreamStatusSlice';
import AudioVideoErrorModal from './AudioVideoErrorModal';
import { resetState, setChatsCount } from '../../store/likesChatsGiftsSlice';

const LiveStreamPage = () => {
  const reconnectAttempts = useRef(0);
  const {
    browserAudioStatus: previousBrowserAudioStatus,
    browserVideoStatus: previousBrowserVideoStatus,
    browserWebRTCStatus: previousBrowserWebRTCStatus,
  } = useSelector((state) => state.liveStreamStatus);

  const { taskId, streamId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const publishSettingsData = useSelector((state) => state.publishSettings);
  const [websocket, setWebsocket] = useState(null);
  const [peerConnection, setPeerConnection] = useState(null);
  const { socket, sendLike, sendGift, sendChat, disconnectSocket } = useSocket();
  const [videoStream, setVideoStream] = useState(null);

  const [endLiveStreamModal, setEndLiveStreamModal] = useState(false);
  const [audioVideoErrorModal, setAudioVideoErrorModal] = useState(false);
  const [isWebRTCError, setIsWebRTCError] = useState(false);
  const isStreamEndedRef = useRef(false);

  const streamedDuration = useRef({ time: 0, flag: false });
  const totalDuration = useRef(0);

  const { data: task } = useGetTask(taskId);
  const { data } = useLiveStreamState(streamId, {
    browserAudioStatus: previousBrowserAudioStatus,
    browserVideoStatus: previousBrowserVideoStatus,
    browserWebRTCStatus: previousBrowserWebRTCStatus,
    streamedDuration: streamedDuration.current.time,
  });

  const memoizedData = useMemo(() => data, [data]);
  const liveStreamState = memoizedData?.live_stream?.state;
  const viewers = memoizedData?.live_stream?.viewers;
  const publishSettings = useMemo(() => publishSettingsData, [publishSettingsData]);

  // const liveStreamState = data?.live_stream?.state;
  // const viewers = data?.live_stream?.viewers;

  const { mutate: streamStarted } = useMutation({
    mutationFn: (streamId) => api.put(`/api/stream/${streamId}/started`),
    onSuccess: ({ data }) => {
      // setDuration(data.data?.duration);

      streamedDuration.current = { time: data.data?.streamedDuration, flag: true };

      totalDuration.current = data.data?.totalDuration;
    },
  });

  console.log('streamedDuration', streamedDuration.current);

  const stopCamera = useCallback(async () => {
    console.log('redux store reset');
    dispatch(resetState()); //reset redux store
    if (videoStream) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
      setVideoStream(null);

      stopPublish(peerConnection, websocket, {
        onSetPeerConnection: (result) => {
          console.log('PeerConnection: ', result.peerConnection);
          setPeerConnection(result.peerConnection);
        },
        onSetWebsocket: (result) => {
          console.log('Websocket: ', result.websocket);
          console.log('web socket connection is closed');
          setWebsocket(result.websocket);
        },
        onPublishStopped: () => {
          console.log('Publish Stopped');
        },
      });
      isStreamEndedRef.current = true;
      disconnectSocket();
      try {
        await api.put(`/api/stream/${streamId}/stop`);
      } catch (error) {
        console.log(error);
      }
      navigate(ROUTES.WELCOME);
    }
  }, [dispatch, navigate, peerConnection, streamId, videoStream, websocket]);

  useEffect(() => {
    const checkMediaDevices = () => {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          reconnectAttempts.current = 0;
          setVideoStream(stream);

          const audioTrack = stream.getAudioTracks()[0];
          const videoTrack = stream.getVideoTracks()[0];

          console.log('audioTrack', audioTrack);
          console.log('videoTrack', videoTrack);

          const currentAudioStatus = audioTrack && audioTrack.readyState === 'live';
          const currentVideoStatus = videoTrack && videoTrack.readyState === 'live';

          // Update audio status only if it changes
          if (currentAudioStatus !== previousBrowserAudioStatus) {
            if (currentAudioStatus) {
              dispatch(setPublishAudioTrackDeviceId(audioTrack.id));
              dispatch(setBrowserAudioStatus(true));
            } else {
              dispatch(setBrowserAudioStatus(false));
              console.warn('Audio track is not live');
              toast.error('Audio device disconnected');
            }
          }

          // Update video status only if it changes
          if (currentVideoStatus !== previousBrowserVideoStatus) {
            if (currentVideoStatus) {
              dispatch(setPublishVideoTrack1DeviceId(videoTrack.id));
              dispatch(setBrowserVideoStatus(true));
            } else {
              dispatch(setBrowserVideoStatus(false));
              console.warn('Video track is not live');
              toast.error('Video device disconnected');
            }
          }
        })
        .catch((error) => {
          console.error('Error accessing media devices:', error);
          if (reconnectAttempts.current < 10) {
            reconnectAttempts.current += 1;
            console.warn(`Reconnection attempt ${reconnectAttempts.current}/10`);
            toast.warn(`Reconnecting... Attempt ${reconnectAttempts.current}/10`);
          } else {
            toast.error('Maximum reconnection attempts reached');
            setAudioVideoErrorModal(true);
          }
        });
    };

    checkMediaDevices();
    const intervalId = setInterval(() => {
      if (reconnectAttempts.current < 10) {
        checkMediaDevices();
      } else {
        clearInterval(intervalId);
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const sourceConnectionInformation = task?.data?.streamDetails?.sourceConnectionInformation;
    dispatch(setPublishSignalingURL(sourceConnectionInformation?.sdp_url));
    dispatch(setPublishApplicationName(sourceConnectionInformation?.application_name));
    dispatch(setPublishStreamName(sourceConnectionInformation?.stream_name));
  }, [dispatch, task?.data?.streamDetails?.sourceConnectionInformation]);

  useEffect(() => {
    if (
      !isStreamEndedRef.current &&
      (!peerConnection || !websocket) &&
      liveStreamState === LIVE_STREAM_STATUS.LIVE &&
      publishSettings.signalingURL
    ) {
      console.log('start-publish', websocket, liveStreamState, publishSettings, streamId);
      startPublish(publishSettings, videoStream, websocket, {
        onError: (error) => {
          console.log('WebSocket + PeerConnection Error', error?.message);
          //triggering the useEffect code - if condition

          socket?.close();
          peerConnection?.close();
          setWebsocket(null);
          setPeerConnection(null);
          dispatch(setBrowserWebRTCStatus(false));
          toast.warn(`Please wait Attempting to Reconnect`);
        },
        onConnectionStateChange: (result) => {
          console.log('ConnectionStateChange', result.connected);
          dispatch(setBrowserWebRTCStatus(result.connected));
          if (result?.connected === true) {
            streamStarted(streamId);
          }
        },
        onSetPeerConnection: (result) => {
          console.log('PeerConnection: ', result.peerConnection);
          setPeerConnection(result.peerConnection);
        },
        onSetWebsocket: (result) => {
          console.log('set-websocket', result.websocket);
          setWebsocket(result.websocket);
        },
        onSetSenders: (senders) => {
          console.log('OnSetSenders: ', senders);
        },
      });
    }
  }, [liveStreamState, publishSettings]);

  console.log('live', websocket, peerConnection);

  useEffect(() => {
    if (data) {
      dispatch(setChatsCount(data.task.chats));
    }
  }, [data]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // For older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // prevent back button
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.pathname);
      console.log('Back button is disabled');
      setEndLiveStreamModal(true);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const endLiveStream = async () => {
    await stopCamera();
  };

  if (!liveStreamState || liveStreamState === LIVE_STREAM_STATUS.STARTING) return <MyVideoLoading />;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          mx: {
            sm: '-24px',
            xs: '-16px',
          },
          height: '100dvh',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            zIndex: 2,
          }}
        >
          <Stack
            spacing={0.5}
            direction={'row'}
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: (theme) => theme.palette.blur.main,
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <EyeIcon />
            <Typography variant={'caption_bold'}>{viewers}</Typography>
          </Stack>
          {task?.data?.type === STREAM_TYPE.REQUEST ? (
            <>
              {totalDuration.current ? (
                <CountdownTimer
                  streamedDuration={streamedDuration}
                  totalDuration={totalDuration}
                  endLiveStream={endLiveStream}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <ClockTimer streamedDuration={streamedDuration} />
          )}
          <Button
            variant='contained'
            onClick={() => setEndLiveStreamModal(true)}
            sx={{
              color: '#fff',
              fontSize: '9px',
              fontWeight: 600,
              backgroundColor: '#FF335B',
              borderRadius: 50,
              '&:hover': {
                backgroundColor: '#FF335B',
                boxShadow: 'none',
              },
            }}
          >
            End Live Stream
          </Button>
        </Box>
        <Box
          sx={{
            position: 'relative',
            top: 66,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            zIndex: 2,
            color: '#fff',
          }}
        >
          <Typography variant={'h5_semibold'}>{task?.data?.title}</Typography>
        </Box>
        <PlayerSkin
          taskId={taskId}
          streamId={streamId}
          task={task}
          socket={socket}
          sendLike={sendLike}
          sendGift={sendGift}
          sendChat={sendChat}
        />
        <Webcam
          mirrored={true}
          videoConstraints={{ facingMode: 'user' }}
          style={{
            position: 'absolute',
            textAlign: 'center',
            zIndex: 1,
            left: 0,
            top: 0,
            width: '100%',
            height: '100dvh',
            objectFit: 'cover',
          }}
        />
      </Box>

      <EndLiveStreamModal
        open={endLiveStreamModal}
        handleClose={() => setEndLiveStreamModal(false)}
        endLiveStream={endLiveStream}
      />

      {/* <AudioVideoErrorModal
        isWebRTCError={isWebRTCError}
        open={audioVideoErrorModal}
        handleClose={() => setAudioVideoErrorModal(false)}
        handleRoute={async () => {
          await stopCamera();
          navigate('/');
        }}
      /> */}
    </>
  );
};

export default LiveStreamPage;
