import React from 'react';
import api from '../../config/api';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import ClockIcon from '../../assets/icons/ClockIcon';
import ROUTES from '../../routes';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';

const getUserWithTasks = async (userId) => {
  const { data } = await api.get(`/api/message/user/${userId}?page=1&size=5`);
  return data;
};
const updateMessageNotificationStatus = async (taskId) => {
  try {
    await api.post(`/api/message/read/${taskId}`);
  } catch (e) {
    console.error(e);
  }
};

const UserMessagePage = () => {
  const { userId } = useParams();
  const {
    data: userWithTasks,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getUserWithTasks', userId],
    queryFn: () => getUserWithTasks(userId),
  });
  const navigate = useNavigate();
  if (isLoading) return <Loading />;
  if (isError) return 'Error';
  const { user, taskMessage } = userWithTasks.data;
  return (
    <Box>
      <TopNavigation title={'Messages'} />
      <TopNavigationSpace />
      <Stack mt={2.5} gap={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
            p: '12px 20px',
            border: (theme) => `1px solid ${theme.palette.border.main}`,
            backgroundColor: (theme) => theme.palette.dark.light,
          }}
        >
          <CustomAvatar avatarUrl={user.profile.avatarUrl} name={user.name} />
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant='h5_semibold'>{user.name}</Typography>
            <Typography color='text.light' variant='h6_semibold'>
              {user.profile.streamCount} Videos Created
            </Typography>
          </Stack>
          <Button
            size='small'
            variant='contained'
            onClick={() => navigate(ROUTES.REQUEST_VIDEO_TO_STREAMER.replace(':streamerId', userId))}
          >
            Request
          </Button>
        </Box>
        {taskMessage.map((message) => (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
              alignItems: 'center',
              gap: 1,
              borderRadius: 1,
              p: '12px 20px',
              border: (theme) => `1px solid ${theme.palette.border.main}`,
              cursor: 'pointer',
            }}
            onClick={async () => {
              await updateMessageNotificationStatus(message.task._id);
              navigate(user?._id === message.task.user 
                ? ROUTES.CHAT_USER.replace(':taskId', message.task._id) 
                : ROUTES.CHAT_CREATOR.replace(':taskId', message.task._id));
            }}
          >
            <Stack spacing={1} sx={{ flex: 1, gridColumn: 'span 10 / span 10' }}>
              <Typography variant='h5_semibold'>{message.task.title}</Typography>
              <Stack spacing={0.5} direction='row' alignItems='center'>
                <ClockIcon />
                <Typography color='text.light' variant='h6_semibold'>
                  {dayjs(message.task.createdAt).fromNow()}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={1} alignItems='center' sx={{ gridColumn: 'span 2 / span 2' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  size='small'
                  // onClick={async () => {
                  //   await updateMessageNotificationStatus(message.task._id);
                  //   navigate(ROUTES.CHAT_USER.replace(':taskId', message.task._id));
                  // }}
                >
                  <RightArrowIcon variant='dark' size={18} />
                </IconButton>
              </Box>

              {user?._id === message.task.user ? (
                <Typography
                  variant='caption_bold'
                  p='4px 8px'
                  sx={{
                    borderRadius: 8,
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    width: 'fit-content',
                  }}
                >
                  Receive
                </Typography>
              ) : (
                <Typography
                  variant='caption_bold'
                  p='4px 8px'
                  sx={{ borderRadius: 8, backgroundColor: (theme) => theme.palette.primary.main, width: 'fit-content' }}
                >
                  Sent
                </Typography>
              )}
              <Box></Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default UserMessagePage;
