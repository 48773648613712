import React from 'react';

function RankingIcon({ size = 60 }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' viewBox={`0 0 16 16`} >
      <path d="M9.97448 6.08562L10.9645 8.06562C11.0995 8.33562 11.4595 8.60562 11.7595 8.65062L13.552 8.95062C14.6995 9.14562 14.9695 9.97062 14.1445 10.7956L12.7495 12.1906C12.517 12.4231 12.382 12.8806 12.457 13.2106L12.8545 14.9431C13.1695 16.3081 12.442 16.8406 11.2345 16.1281L9.55448 15.1306C9.24698 14.9506 8.75199 14.9506 8.44449 15.1306L6.76448 16.1281C5.55698 16.8406 4.82948 16.3081 5.14448 14.9431L5.542 13.2106C5.617 12.8881 5.48199 12.4306 5.24949 12.1906L3.8545 10.7956C3.0295 9.97062 3.29948 9.13812 4.44698 8.95062L6.23949 8.65062C6.53949 8.59812 6.89949 8.33562 7.03449 8.06562L8.0245 6.08562C8.557 5.01313 9.44198 5.01313 9.97448 6.08562Z" fill="#FDCC0A"/>
      <path opacity="0.4" d="M4.49902 7.3125C4.19152 7.3125 3.93652 7.0575 3.93652 6.75V1.5C3.93652 1.1925 4.19152 0.9375 4.49902 0.9375C4.80652 0.9375 5.06152 1.1925 5.06152 1.5V6.75C5.06152 7.0575 4.80652 7.3125 4.49902 7.3125Z" fill="#FDCC0A"/>
      <path opacity="0.4" d="M13.499 7.3125C13.1915 7.3125 12.9365 7.0575 12.9365 6.75V1.5C12.9365 1.1925 13.1915 0.9375 13.499 0.9375C13.8065 0.9375 14.0615 1.1925 14.0615 1.5V6.75C14.0615 7.0575 13.8065 7.3125 13.499 7.3125Z" fill="#FDCC0A"/>
      <path opacity="0.4" d="M8.99902 3.5625C8.69152 3.5625 8.43652 3.3075 8.43652 3V1.5C8.43652 1.1925 8.69152 0.9375 8.99902 0.9375C9.30652 0.9375 9.56152 1.1925 9.56152 1.5V3C9.56152 3.3075 9.30652 3.5625 8.99902 3.5625Z" fill="#FDCC0A"/>
    </svg>
  );
}

export default RankingIcon;
