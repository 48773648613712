import { Box } from '@mui/material';
import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import MenuHeader from '../WelcomePage/MenuHeader';
import NoLiveStreamCard from '../WelcomePage/components/LiveStreamComponent/NoLiveStreamCard';
import Loading from '../../components/Loading/Loading';
import { useInfiniteQuery } from '@tanstack/react-query';
import api from '../../config/api';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';
import LiveStreamCard from '../WelcomePage/components/LiveStreamComponent/LiveStreamCard';
import Stack from '@mui/material/Stack';
import RecordedStreamCard from '../WelcomePage/components/LiveStreamComponent/RecordedStreamCard';

const getLiveStream = async ({ pageParam }) => {
  const { data } = await api.get(`/api/stream?page=${pageParam}&size=10&state=started&state=starting`);
  return data;
};

const AllLiveStreamPage = () => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['liveStreamInfinity'],
    queryFn: getLiveStream,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
  const allLiveStreams = useInfiniteQueryReducedResult(data);
  if (isLoading || isFetching) return <Loading />;
  return (
    <Box sx={{ position: 'relative', pb: 8 }}>
      <TopNavigation />
      <TopNavigationSpace />
      <Box mt={2} width={'100%'}>
        <MenuHeader title={'Live Streams'} isViewAll={false} />
        <Stack spacing={2} mt={2} pb={2} sx={{ overflowX: 'hidden', alignItems: 'center', width: '100%' }}>
          {allLiveStreams?.length > 0 ? (
            allLiveStreams?.map((liveShow, index) =>
              liveShow?.streamStatus === 'STOPPED' ? (
                <RecordedStreamCard
                  key={liveShow._id}
                  data={liveShow}
                  isLoading={isLoading}
                  fetchNextPage={fetchNextPage}
                  isFetching={isFetching}
                  hasNextPage={hasNextPage}
                  index={index}
                  totalCount={allLiveStreams?.length}
                />
              ) : (
                <LiveStreamCard
                  key={liveShow._id}
                  data={liveShow}
                  isLoading={isLoading}
                  fetchNextPage={fetchNextPage}
                  isFetching={isFetching}
                  hasNextPage={hasNextPage}
                  index={index}
                  totalCount={allLiveStreams?.length}
                />
              ),
            )
          ) : (
            <NoLiveStreamCard />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default AllLiveStreamPage;
