import React from 'react';
import CustomDialog from '../CustomDialog/CustomDialog';
import { Button, Stack, Typography } from '@mui/material';
import InfoIconWithBackground from '../../assets/icons/InfoIconWithBackground';

const StreamRejoinModel = ({ name, open = false, handleClose, onClick }) => {

  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'}>
        <InfoIconWithBackground />
        <Typography variant='h6'>
            {`Hey `} 
            <span style={{ fontWeight: 'bold' }}>{name}</span>
            {`, your last stream is still live, and your viewers are waiting!`}
        </Typography>
        <Button fullWidth variant='contained' onClick={onClick}>
            Re-Join
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default StreamRejoinModel;
