import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

export const truncateTitle = (title, length) => {
  return title?.length > length ? title.substring(0, length) + '...' : title;
};

export function formatCount(count) {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; // Thousands
  }
  return count.toString(); // If less than 1000, return the number as is
}

export const trackUTMWithEmail = (userId) => {
  const utmSource = Cookies.get('utm_source');
  const utmMedium = Cookies.get('utm_medium');
  const utmCampaign = Cookies.get('utm_campaign');
  const clickId = Cookies.get('click_id');
  const publisherId = Cookies.get('publisher_id');

  if (utmSource && utmMedium && utmCampaign) {
    ReactGA.gtag('event', 'signup-avasar', {
      utmSource,
      utmMedium,
      utmCampaign,
      clickId,
      publisherId,
      userId,
    });
  }
  Cookies.remove('utm_source');
  Cookies.remove('utm_medium');
  Cookies.remove('utm_campaign');
  Cookies.remove('click_id');
  Cookies.remove('publisher_id');
};

export const storeUTMInCookies = (options = { expires: 7, path: '/' }) => {
  const params = new URLSearchParams(window.location.search);
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'click_id', 'publisher_id'];

  utmKeys.forEach((key) => {
    if (params.has(key)) {
      const value = params.get(key);
      Cookies.set(key, value, options);
    }
  });
};

export const isOneWeekBefore = (timestamp) => {
  // Extend dayjs with plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Convert both dates to UTC to ensure timezone consistency
  const currentDate = dayjs().utc();
  const targetDate = dayjs(timestamp).utc();

  // Calculate difference in milliseconds
  const diffInMs = targetDate.diff(currentDate);

  // Check if difference is between 0 and exactly 7 days (in milliseconds)
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

  return diffInMs > 0 && diffInMs <= sevenDaysInMs;
};
