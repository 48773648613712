import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { auth, onAuthStateChanged, signOut } from '../config/firebase';
import Loading from '../components/Loading/Loading';

const AuthContext = createContext();
export const useAuthUser = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSignOut = useCallback(async () => {
    await signOut(auth);
  }, [auth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Set user once we know the auth state
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return <AuthContext.Provider value={{ user, handleSignOut }}>{children}</AuthContext.Provider>;
}
