import * as React from 'react';

function CancelIcon(props) {
  return (
    <svg
      width={props.size || 25}
      height={props.size || 25}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.54.5H7.46C3.106.5.5 3.106.5 7.46v10.08c0 4.354 2.606 6.96 6.96 6.96h10.08c4.354 0 6.96-2.606 6.96-6.96V7.46C24.5 3.106 21.894.5 17.54.5zm1.714 15.634a2.214 2.214 0 010 3.12 2.289 2.289 0 01-1.577.617 2.268 2.268 0 01-1.543-.617L12.5 15.62l-3.6 3.634a2.289 2.289 0 01-1.577.617 2.268 2.268 0 01-1.543-.617 2.214 2.214 0 010-3.12L9.414 12.5 5.78 8.9a2.214 2.214 0 010-3.12 2.214 2.214 0 013.12 0l3.6 3.634 3.634-3.634a2.214 2.214 0 013.12 0 2.214 2.214 0 010 3.12l-3.634 3.6 3.634 3.634z'
        fill='#292D32'
      />
    </svg>
  );
}

export default CancelIcon;
