import React from 'react';

function HeartIcon({ size }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' viewBox={`0 0 16 16`} >
      <path opacity="0.4" d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z" fill="#FDCC0A"/>
      <path d="M8.2475 11.75C8.1125 11.795 7.88 11.795 7.745 11.75C6.575 11.3525 3.95 9.67999 3.95 6.84499C3.95 5.59249 4.955 4.58 6.2 4.58C6.935 4.58 7.5875 4.9325 8 5.4875C8.405 4.94 9.065 4.58 9.8 4.58C11.045 4.58 12.05 5.59249 12.05 6.84499C12.05 9.67999 9.425 11.3525 8.2475 11.75Z" fill="#FDCC0A"/>
    </svg>
  );
}

export default HeartIcon;
