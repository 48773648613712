import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ClockIcon from '../../../../assets/icons/ClockIcon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import useInfiniteScrollUtils from '../../../../utils/useInfiniteScrollUtils';
import CoinIcon from '../../../../assets/icons/CoinIcon';
import CustomAvatar from '../../../../components/CustomAvatar/CustomAvatar';
import LoginModal from '../../../../components/LoginModal/Login';
import isGuestUser from '../../../../hooks/isGuestUser';
import { truncateTitle } from '../../../../utils/helpers';

dayjs.extend(relativeTime);

const TopRequestCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const observer = useRef();
  const navigate = useNavigate();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  return (
    <>
      <Card
        sx={{ backgroundColor: (theme) => theme.palette.dark.light }}
        ref={index === totalCount - 1 ? lastElementRef : null}
      >
        <CardContent>
          <Stack spacing={2} direction='column' sx={{ justifyContent: 'space-between' }}>
            <Stack direction='row' sx={{ justifyContent: 'space-between' }} spacing={1}>
              <Stack spacing={1} direction='column' sx={{ width: '80%' }}>
                <Typography variant='h5_bold' className='truncate'>
                  {data?.title}
                </Typography>
                <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                  <ClockIcon />
                  <Typography variant='h6'>{dayjs(data?.createdAt).fromNow()}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1} direction='column' sx={{ width: '20%' }}>
                <Stack direction='row' spacing={0.5} sx={{ alignItems: 'center' }}>
                  <CoinIcon />
                  <Typography variant='h6'>${data?.amount}</Typography>
                </Stack>
                <Stack direction='row' spacing={0.5} sx={{ alignItems: 'center' }}>
                  <Box sx={{ minWidth: '14px' }}>
                    <ClockIcon />
                  </Box>

                  <Typography variant='h6' sx={{ whiteSpace: 'nowrap' }}>
                    {data?.duration} min
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
              <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                <CustomAvatar avatarUrl={data?.user?.profile?.avatarUrl} name={data?.user?.name} />
                <Typography variant='h6_bold' component='div' className='truncate'>
                  {truncateTitle(data?.user?.name, 15)}
                </Typography>
              </Stack>
              <Button
                variant='contained'
                sx={{ borderRadius: 50, fontSize: '12px' }}
                onClick={() => (isGuestUser() ? setOpenLoginModal(true) : navigate(`/submit-proposal/${data?._id}`))}
              >
                Execute Request
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
};

export default TopRequestCard;
