import api from '../config/api';
import { useQuery } from '@tanstack/react-query';
import isGuestUser from './isGuestUser';

const getMessageNotification = async () => {
  if (isGuestUser()) return { data: null };
  const { data } = await api.get('/api/message/read');
  return data;
};

const useGetMessageNotification = () => {
  return useQuery({
    queryKey: ['messageNotification'],
    queryFn: getMessageNotification,
    refetchOnWindowFocus: false,
  });
};

export default useGetMessageNotification;
