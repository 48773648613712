import React from 'react';
import Card from '@mui/material/Card';
import { Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import EyeIcon from '../../../../assets/icons/EyeIcon';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CustomAvatar from '../../../../components/CustomAvatar/CustomAvatar';
import PlayIcon from '../../../../assets/icons/PlayIcon';
// import useGetThumbnail from '../../../../hooks/useGetThumbnail';
import ROUTES from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api from '../../../../config/api';
import { truncateTitle } from '../../../../utils/helpers';

const getRecording = async (streamId) => {
  const { data } = await api.get(`/api/stream/recordings/${streamId}`);
  return data;
};

const RecordedStreamCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();
  const streamId = data.streamDetails.liveStreamId;
  const { data: recording } = useQuery({
    queryKey: ['recording', streamId],
    queryFn: () => getRecording(streamId),
    refetchOnWindowFocus: false,
  });
  const taskId = data?._id;
  const handleLiveStream = () => {
    navigate(ROUTES.RECORDING_PLAY + '?streamId=' + streamId + '&taskId=' + taskId);
  };
  const username = data.type === 'REQUEST' ? data?.accepted?.user?.name : data?.user?.name;
  const avatarUrl = data.type === 'REQUEST' ? data?.accepted?.user?.profile?.avatarUrl : data?.user?.profile?.avatarUrl;

  return (
    <Card sx={{ width: '100%', borderRadius: '20px', minWidth: '180px', margin: '8px' }}>
      <Box
        sx={{
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',

          position: 'relative',
          cursor: 'pointer',
          background: `linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 30%), url(${recording?.data?.asset[0]?.asset?.thumbnail_url}) no-repeat center center`,
          backgroundSize: 'cover',
        }}
        onClick={handleLiveStream}
      >
        <Stack spacing={0.5} direction='row' sx={{ alignSelf: 'end', padding: '16px 16px 0px 0px' }}>
          <Stack
            spacing={0.5}
            direction='row'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#5F5D5D80',
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <EyeIcon />
            <Typography variant='caption_bold'>{data?.viewers}</Typography>
          </Stack>
        </Stack>
        <Typography
          variant='h6_bold'
          sx={{
            color: '#fff',
            alignSelf: 'start',
            padding: '0px 0px 20px 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100% - 40px)',
          }}
        >
          {data?.title}
        </Typography>
      </Box>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack spacing={1} direction='row' alignItems='center' sx={{ width: '80%' }}>
          <CustomAvatar avatarUrl={avatarUrl} name={username} />
          <Typography
            variant='h5_bold'
            sx={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {username}
          </Typography>
        </Stack>
        <IconButton
          color='primary'
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={handleLiveStream}
        >
          <PlayIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default RecordedStreamCard;
