import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import WarningIcon from '../../assets/icons/WarningIcon';
import CustomDialog from '../../components/CustomDialog/CustomDialog';

const AudioVideoErrorModal = ({ open = false, handleClose, handleRoute, isWebRTCError }) => {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'} textAlign='center'>
        <WarningIcon />
        <Stack spacing={1}>
          <Typography variant='h5_dark'>
            {isWebRTCError
              ? 'We are unable to establish connection with the server at the moment'
              : 'Sorry we are not able to access your Camera and Microphone!'}
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Button variant='contained' fullWidth sx={{ borderRadius: 50 }} onClick={handleRoute} size='large'>
            Close
          </Button>
        </Stack>
      </Stack>
    </CustomDialog>
  );
};

export default AudioVideoErrorModal;
