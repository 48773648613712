import React from 'react';
import { Box, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { useQuery } from '@tanstack/react-query';
import api from '../../config/api';
import Stack from '@mui/material/Stack';
import Loading from '../../components/Loading/Loading';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import useGetWallet from '../../hooks/useGetWallet';
import WalletBalance from '../../components/WalletBalance/WalletBalance';
import { TRANSACTION_RESPONSE, TRANSACTION_TYPE, TRANSACTIONS } from '../../utils/constant';
import currencyFormat from '../../utils/currencyFormat';
import dayjs from 'dayjs';

const WalletDetails = ({ transaction }) => {
  const { amount, status, type, txnType, createdAt } = transaction;
  const TRANSACTIONS_DETAILS = {
    ADD_WALLET: 'Added Money to Wallet',
    WITHDRAWAL: 'Withdrawal',
    REQUEST_TASK: 'Booked Stream',
    STREAMER: 'Booking Request from user',
    GO_LIVE: 'Go Live',
    REWARD: 'Welcome Reward',
  };

  return (
    <Stack direction='row' alignItems='center'>
      <Stack flexGrow={1}>
        <Typography variant='h6_bold'>
          {txnType === TRANSACTION_TYPE.REFUND
            ? 'Refund'
            : type === TRANSACTIONS.STREAMER
              ? TRANSACTIONS_DETAILS[`${type}`].replace('user', transaction?.task?.user?.name)
              : TRANSACTIONS_DETAILS[`${type}`]}
        </Typography>
        <Typography variant='h6'>{dayjs(createdAt).format('DD MMM YYYY, HH:mm')}</Typography>
      </Stack>
      <Stack>
        <Typography
          variant='h6_dark'
          color={(theme) => {
            if (status === TRANSACTION_RESPONSE.PENDING) return theme.palette.warning.main;
            else if (txnType === TRANSACTION_TYPE.DEBIT) return theme.palette.error.main;
            else if (status === TRANSACTION_RESPONSE.SUCCESS || status === TRANSACTION_TYPE.REWARD)
              return theme.palette.success.main;
          }}
        >
          {txnType === TRANSACTION_TYPE.CREDIT || txnType === TRANSACTION_TYPE.REFUND ? '+' : '-'}
          {currencyFormat(Math.abs(amount))}
        </Typography>
        <Typography variant='h6'>
          {status === TRANSACTION_RESPONSE.SUCCESS && 'Success'}
          {status === TRANSACTION_RESPONSE.PENDING && 'Pending'}
          {status === TRANSACTION_RESPONSE.REJECT && 'Rejected'}
        </Typography>
      </Stack>
    </Stack>
  );
};

const WalletPage = (props) => {
  const navigate = useNavigate();

  const { data: wallet, isLoading } = useGetWallet();
  const getWalletTransaction = async () => {
    const { data } = await api.get('/api/user/wallet/txn?page=1&size=100');
    return data;
  };
  const { data: walletTransaction } = useQuery({
    queryKey: ['walletTransaction'],
    queryFn: getWalletTransaction,
  });
  const walletActions = [
    { label: 'Add', onClick: () => navigate(ROUTES.WALLET_ADD_MONEY) },
    {
      label: 'Withdraw',
      onClick: () => navigate(ROUTES.WALLET_WITHDRAW_MONEY),
    },
    { label: 'Manage', onClick: () => navigate(ROUTES.WALLET_MANAGE) },
  ];
  if (isLoading) return <Loading />;
  return (
    <Box sx={{ pb: 8 }}>
      <TopNavigation title='Wallet' />
      <TopNavigationSpace />
      <Stack spacing={2} mt={2}>
        <WalletBalance wallet={wallet} />
        <Box display='flex' justifyContent='space-between' alignItems='center' gap={1.5}>
          {walletActions.map((action) => (
            <Button
              variant='contained'
              size='large'
              sx={{ borderRadius: 50 }}
              fullWidth
              onClick={action.onClick}
              key={action.label}
            >
              {action.label}
            </Button>
          ))}
        </Box>
        <Stack spacing={2.5}>
          <Stack direction='row'>
            <Typography flexGrow={1} variant='h6_dark' sx={{ color: (theme) => theme.palette.lightText.light }}>
              History
            </Typography>
          </Stack>

          {walletTransaction?.data?.map((txn) => (
            <WalletDetails
              key={txn._id}
              transaction={txn}
              amount={txn?.amount}
              status={txn?.status}
              user={txn.user}
              type={txn.type}
              fee={txn?.fee || txn?.task?.fee}
              txn={txn}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default WalletPage;
