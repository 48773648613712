import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import TopRequestComponent from './components/TopRequestComponent/TopRequestComponent';
import { isShowBottomDrawer } from '../../components/BottomNavigation/BottomNavigation';
import LiveStreamComponent from './components/LiveStreamComponent/LiveStreamComponent';
import TopStreamersComponent from './components/TopStreamersComponent/TopStreamersComponent';
import { isNewUser, isRequestVideoFormPending, setting } from '../../store';
import useSetting from '../../hooks/useSetting';
import WelcomeReward from '../../components/WelcomeReward/WelcomeReward';
import ReactGA from 'react-ga4';
import Stack from '@mui/material/Stack';
import { IconButton, Typography } from '@mui/material';
import ReferIcon from '../../assets/icons/ReferIcon';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import Button from '@mui/material/Button';
import PopularVideos from './components/PopularVideos/PopularVideos';
import LoginModal from '../../components/LoginModal/Login';
import isGuestUser from '../../hooks/isGuestUser';
import ActiveStreamComponent from './components/ActiveStreamComponent';

const WelcomePage = () => {
  const [openRewardModal, setOpenRewardModal] = useState(isNewUser.value);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    isShowBottomDrawer.value = true;
    return () => {
      isShowBottomDrawer.value = false;
    };
  }, []);
  setting.value = useSetting();
  console.log(isNewUser.value);
  const handleCloseRewardModal = () => {
    setOpenRewardModal(false);
    isNewUser.value = false;
  };
  const navigate = useNavigate();
  ReactGA.send({ hitType: 'pageview', page: '/welcome', title: 'Welcome Page' });
  useEffect(() => {
    const requestVideoForm = JSON.parse(localStorage.getItem('requestVideoForm'));
    isRequestVideoFormPending.value = requestVideoForm ? Object.keys(requestVideoForm).length > 0 : false;
  }, []);

  return (
    <>
      <Box sx={{ pb: 8, width: '100%' }}>
        <TopNavigation />
        <TopNavigationSpace />
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            mx: { sm: '-24px', xs: '-16px' },
            px: { sm: '24px', xs: '16px' },
            py: 1.5,
            width: { sm: 'calc(100% + 48px)', xs: 'calc(100% + 32px)' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <ReferIcon />
          <Typography variant='h5_dark'>Invite Friends and Earn Rewards</Typography>
          <IconButton
            onClick={() => {
              isGuestUser() ? setOpenLoginModal(true) : navigate(ROUTES.REFER_AND_EARN);
            }}
          >
            <RightArrowIcon variant='dark' />
          </IconButton>
        </Box>
        {isRequestVideoFormPending.value && (
          <Stack
            direction='row'
            width='100%'
            mt={4}
            p='20px 24px'
            sx={{ border: (theme) => `1px solid ${theme.palette.border.main}`, borderRadius: '20px' }}
          >
            <Stack width='100%' spacing={1.5}>
              <Typography variant='h5_dark' textAlign='center'>
                You have a Pending Stream Request
              </Typography>
              <Stack direction='row' width='100%' spacing={1.5}>
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => navigate(`${ROUTES.REQUEST_VIDEO_FORM}?type=continue`)}
                >
                  Continue
                </Button>
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={() => {
                    navigate(`${ROUTES.REQUEST_VIDEO_FORM}?type=startAgain`);
                  }}
                >
                  Start again
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
        <TopStreamersComponent />
        <LiveStreamComponent />
        <PopularVideos />
        <TopRequestComponent />
      </Box>
      <WelcomeReward open={openRewardModal} handleClose={handleCloseRewardModal} />
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
      {!isGuestUser() && <ActiveStreamComponent />}
    </>
  );
};

export default WelcomePage;
