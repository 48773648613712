import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { setLikesCount, setChatsCount, setGiftsCount, addChat, setGifts } from '../store/likesChatsGiftsSlice';
import { calculateTotalGifts } from '../transformers/liveStreamTransformer';

const URL = process.env.REACT_APP_BASE_URL_API;

const useSocket = () => {
  const [socket] = useState(io(URL, { autoConnect: false }));
  const [isConnected, setIsConnected] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Connect the socket
    socket.connect();

    // Handle socket connection events
    socket.on('connect', () => {
      console.log('Chat socket connected');
      setIsConnected(true);
      setConnectionError(null); // Reset error on successful connection
    });

    socket.on('connect_error', (error) => {
      console.error('Chat socket connection error:', error);
      setConnectionError(error.message); // Store the error message for user feedback
    });

    socket.on('disconnect', (reason) => {
      console.warn(`Chat socket disconnected due to: ${reason}`);
      setIsConnected(false);
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.info(`Chat reconnection attempt #${attempt}`);
    });

    socket.on('reconnect_failed', () => {
      console.error('Chat socket reconnection failed.');
      setConnectionError('Reconnection failed. Please try again later.');
    });

    // Listen for real-time count updates
    socket.on('counts_update', (data) => {
      console.log('counts_update', data);
      dispatch(setLikesCount(data.likesCount));
      dispatch(setChatsCount(data.chatsCount));
      dispatch(setGifts(data.giftsCount));
      dispatch(setGiftsCount(calculateTotalGifts(data.giftsCount)));
    });

    // Listen for new chat messages
    socket.on('new_chat', (message) => {
      console.log('Received new chat message:', message);
      dispatch(addChat(message));
    });

    // Cleanup on component unmount
    return () => socket.disconnect();
  }, [socket]);

  // Emit events for likes, chats, and gifts
  const sendLike = (data) => socket.emit('send_like', data);
  const sendChat = (data) => socket.emit('send_chat', data);
  const sendGift = (data) => socket.emit('send_gift', data);

  const connectSocket = () => {
    socket.connect();
  };

  const disconnectSocket = () => {
    socket.disconnect();
  };

  return {
    socket,
    isConnected,
    connectionError,
    connectSocket,
    disconnectSocket,
    sendLike,
    sendChat,
    sendGift,
  };
};

export default useSocket;
