import axios from 'axios';
import ROUTES from '../routes';
import { getAuth } from 'firebase/auth';

const getToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    return token;
  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  // baseURL: 'http://localhost:5000',
});

api.interceptors.request.use(
  async (config) => {
    const authToken = await getToken();
    // console.log(authToken);
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    // remove token for map apis
    if (config?.url?.includes('https://maps.googleapis.com')) delete config?.headers?.Authorization;

    return config;
  },
  (error) => {
    localStorage.removeItem('token');
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      // console.log(window.location);
      // localStorage.setItem('intendedRoute', window.location.href);
      window.location.href = ROUTES.WELCOME;
    }
    return Promise.reject(error);
  },
);

export default api;
