import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  likesCount: 0,
  chatsCount: 0,
  gifts: { coins: 0, stars: 0, hearts: 0, totalCount: 0 },
  chats: [],
};

export const likesChatsGiftsSlice = createSlice({
  name: 'likesChatsGifts',
  initialState,
  reducers: {
    setLikesCount(state, action) {
      state.likesCount = action.payload;
    },
    setChatsCount(state, action) {
      state.chatsCount = action.payload;
    },
    setGiftsCount(state, action) {
      state.gifts.totalCount = action.payload;
    },
    setGifts(state, action) {
      state.gifts = { ...state.gifts, ...action.payload };
    },
    addChat(state, action) {
      state.chats.unshift(action.payload);
    },
    setChats(state, action) {
      state.chats = action.payload;
    },
    resetState(state) {
      state.likesCount = 0;
      state.chatsCount = 0;
      state.gifts = { coins: 0, stars: 0, hearts: 0, totalCount: 0 };
      state.chats = [];
    },
  },
});

export const { setLikesCount, setChatsCount, setGiftsCount, addChat, setChats, resetState, setGifts } =
  likesChatsGiftsSlice.actions;

export default likesChatsGiftsSlice.reducer;
