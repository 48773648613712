import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import GetStartedPage from './pages/GetStartedPage/GetStartedPage';
import './App.css';
import ROUTES from './routes';
import { Box, Container, CssBaseline } from '@mui/material';
import Paper from '@mui/material/Paper';
import ForgetPasswordPage from './pages/ForgetPasswordPage/ForgetPasswordPage';
import LiveStreamFormPage from './pages/LiveStreamFormPage/LiveStreamFormPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { StartTokenRefresh } from './components/AutoLogOut';
import RequestVideoPage from './pages/RequestVideoPage/RequestVideoPage';
import LiveStreamPlayerPage from './pages/LiveStreamPlayerPage/LiveStreamPlayerPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import DiscoverPage from './pages/DiscoverPage/DiscoverPage';
import CustomBottomNavigation from './components/BottomNavigation/BottomNavigation';
import SubmitProposalPage from './pages/SubmitProposalPage/SubmitProposalPage';
import RequestVideoToStreamerPage from './pages/RequestVideoToStreamerPage/RequestVideoToStreamerPage';
import MyTaskPage from './pages/MyTaskPage/MyTaskPage';
import PersonalDetailsPage from './pages/PersonalDetailsPage/PersonalDetailsPage';
import EditProfilePage from './pages/EditProfilePage/EditProfilePage';
import MyRequestPage from './pages/MyRequestPage/MyRequestPage';
import ScheduleLivePage from './pages/ScheduleLivePage/ScheduleLivePage';
import MyRequestProposalsPage from './pages/MyRequestProposalsPage/MyRequestProposalsPage';
import MyRequestProposalPage from './pages/MyRequestProposalPage/MyRequestProposalPage';
import ChatUserPage from './pages/ChatUserPage/ChatUserPage';
import ReschedulePage from './pages/ReschedulePage/ReschedulePage';
import TopRequestPage from './pages/TopRequestsPage/TopRequestPage';
import TopStreamersPage from './pages/TopStreamersPage/TopStreamersPage';
import MyVideos from './pages/MyVideos/MyVideos';
import WalletPage from './pages/WalletPage/WalletPage';
import RecordedVideoPlayer from './pages/RecordedVideoPlayer/RecordedVideoPlayer';
import ForgetPasswordCodeVerificationPage from './pages/ForgetPasswordCodeVerificationPage/ForgetPasswordCodeVerificationPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import EmailVerificationPage from './pages/EmailVerificationPage/EmailVerificationPage';
import AllLiveStreamPage from './pages/AllLiveStreamPage/AllLiveStreamPage';
import WalletAddMoneyPage from './pages/WalletAddMoneyPage/WalletAddMoneyPage';
import WalletWithdrawMoneyPage from './pages/WalletWithdrawMoneyPage/WalletWithdrawMoneyPage';
import WalletManagePage from './pages/WalletManagePage/WalletManagePage';
import AddBankAccountPage from './pages/AddBankAccountPage/AddBankAccountPage';
import EditBankAccountPage from './pages/EditBankAccountPage/EditBankAccountPage';
import ReactGA from 'react-ga4';
import FeedbackPage from './pages/FeedbackPage/FeedbackPage';
import NoPageFoundPage from './pages/NoPageFoundPage/NoPageFoundPage';
import StreamerProfile from './pages/StreamerProfile/StreamerProfile';
import NotificationPage from './pages/NotificationPage/NotificationPage';
import ReferEarnPage from './pages/ReferEarnPage/ReferEarnPage';
import ReferralCodeInputPage from './pages/ReferralCodeInputPage/ReferralCodeInputPage';
import ReferEarnTransactionPage from './pages/ReferEarnTransactionPage/ReferEarnTransactionPage';
import PopularVideosPage from './pages/PopularVideosPage/PopularVideosPage';
import PropTypes from 'prop-types';
import WelcomePage from './pages/WelcomePage/WelocomePage';
import HelmetMetaData from './components/ReactHelmet/ReactHealmet';
import MessagesPage from './pages/MessagesPage/MessagesPage';
import UserMessagePage from './pages/UserMessagePage/UserMessagePage';
import LiveStreamPage from './pages/LiveStreamPage/LiveStreamPage';
import HotJar from '@hotjar/browser';
import { useAuthUser } from './contexts/AuthContext';
import LiveStreamPageTest1 from './pages/LiveStreamPage/LiveStreamPageTest1';

const siteId = process.env.REACT_APP_HOT_JAR_SITE_ID;
const hotJarVersion = process.env.REACT_APP_HOT_JAR_VERSION;

HotJar.init(siteId, hotJarVersion);

// const PrivateRoute = ({ pathname }) => {
//   const token = localStorage.getItem('token');
//   if (token === null) localStorage.setItem('intendedRoute', pathname);
//   return token ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
// };
const PrivateRoute = ({ pathname }) => {
  const user = useAuthUser();
  return user ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
};

PrivateRoute.prototypes = {
  pathname: PropTypes.string,
};

function App() {
  const { pathname } = useLocation();

  ReactGA.initialize('process.env.REACT_APP_GA_MEASUREMENT_ID');
  return (
    // <Portal container={document.body}>
    <Box sx={{ backgroundColor: '#364043', position: 'relative' }}>
      <CssBaseline />
      {/* <AutoLogOut /> */}
      {/* <StartTokenRefresh /> */}
      <Container
        maxWidth='xs'
        sx={{
          minHeight: '100dvh',
          borderRadius: '0',
          position: 'relative',
          // pb: 8,
        }}
        component={Paper}
        elevation={1}
      >
        <HelmetMetaData title={'bunpun - Welcome'} />
        <Routes>
          <Route path={ROUTES.HOME} element={<GetStartedPage />} />
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
          <Route path={ROUTES.EMAIL_VERIFICATION} element={<EmailVerificationPage />} />
          <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPasswordPage />} />
          <Route path={ROUTES.FORGET_PASSWORD_CODE_VERIFICATION} element={<ForgetPasswordCodeVerificationPage />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
          <Route path={ROUTES.WELCOME} element={<WelcomePage />} />
          <Route path={ROUTES.STREAMER_PROFILE} element={<StreamerProfile />} />
          <Route path={ROUTES.LIVE_PLAY} element={<LiveStreamPlayerPage />} />
          <Route path={ROUTES.RECORDING_PLAY} element={<RecordedVideoPlayer />} />

          <Route element={<PrivateRoute pathname={pathname} />}>
            <Route path={ROUTES.WELCOME} element={<WelcomePage />} />
            <Route path={ROUTES.REFERRAL_CODE_INPUT} element={<ReferralCodeInputPage />} />
            <Route path={ROUTES.LIVE_FORM} element={<LiveStreamFormPage />} />
            <Route path={ROUTES.LIVE} element={<LiveStreamPage />} />
            {/* <Route path={ROUTES.LIVE} element={<LiveStreamPageTest1 />} /> */}
            {/*<Route path={ROUTES.LIVE_PLAY} element={<LiveStreamPlayerPage />} />*/}
            <Route path={ROUTES.REQUEST_VIDEO_TO_STREAMER} element={<RequestVideoToStreamerPage />} />
            <Route path={ROUTES.REQUEST_VIDEO_FORM} element={<RequestVideoPage />} />
            <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
            <Route path={ROUTES.REFER_AND_EARN} element={<ReferEarnPage />} />
            <Route path={ROUTES.REFER_AND_EARN_TRANSACTION} element={<ReferEarnTransactionPage />} />
            {/*<Route path={ROUTES.STREAMER_PROFILE} element={<StreamerProfile />} />*/}
            <Route path={ROUTES.EDIT_PROFILE} element={<EditProfilePage />} />
            <Route path={ROUTES.PERSONAL_DETAILS} element={<PersonalDetailsPage />} />
            <Route path={ROUTES.MY_TASK} element={<MyTaskPage />} />
            <Route path={ROUTES.MY_REQUEST} element={<MyRequestPage />} />
            <Route path={ROUTES.DISCOVER} element={<DiscoverPage />} />
            <Route path={ROUTES.LIVE_ALL} element={<AllLiveStreamPage />} />
            <Route path={ROUTES.SCHEDULE_LIVE} element={<ScheduleLivePage />} />
            <Route path={ROUTES.MY_REQUEST_PROPOSALS} element={<MyRequestProposalsPage />} />
            <Route path={ROUTES.MY_REQUEST_PROPOSAL} element={<MyRequestProposalPage />} />
            <Route path={ROUTES.CHAT_USER} element={<ChatUserPage />} />
            <Route path={ROUTES.CHAT_CREATOR} element={<ChatUserPage />} />
            <Route path={ROUTES.RESCHEDULE} element={<ReschedulePage />} />
            <Route path={ROUTES.TOP_REQUEST} element={<TopRequestPage />} />
            <Route path={ROUTES.TOP_STREAMERS} element={<TopStreamersPage />} />
            <Route path={ROUTES.SUBMIT_PROPOSAL} element={<SubmitProposalPage />} />
            <Route path={ROUTES.MY_VIDEOS} element={<MyVideos />} />
            <Route path={ROUTES.WALLET} element={<WalletPage />} />
            <Route path={ROUTES.NOTIFICATIONS} element={<NotificationPage />} />
            <Route path={ROUTES.MESSAGES} element={<MessagesPage />} />
            <Route path={ROUTES.USER_MESSAGES} element={<UserMessagePage />} />
            <Route path={ROUTES.WALLET_ADD_MONEY} element={<WalletAddMoneyPage />} />
            <Route path={ROUTES.WALLET_WITHDRAW_MONEY} element={<WalletWithdrawMoneyPage />} />
            <Route path={ROUTES.WALLET_MANAGE} element={<WalletManagePage />} />
            <Route path={ROUTES.ADD_BANK_ACCOUNT} element={<AddBankAccountPage />} />
            <Route path={ROUTES.EDIT_BANK_ACCOUNT} element={<EditBankAccountPage />} />
            {/*<Route path={ROUTES.RECORDING_PLAY} element={<RecordedVideoPlayer />} />*/}
            <Route path={ROUTES.FEEDBACK} element={<FeedbackPage />} />
            <Route path={ROUTES.POPULAR_VIDEOS} element={<PopularVideosPage />} />
          </Route>
          <Route path='*' element={<NoPageFoundPage />} />
        </Routes>
        {pathname.includes('_') || pathname === ROUTES.HOME ? null : <CustomBottomNavigation />}
      </Container>
      <ToastContainer position='top-right' autoClose={2000} />
    </Box>
    // </Portal>
  );
}

export default App;
