import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getThumbnail = async (streamId) => {
  const { data } = await api.get(`/api/stream/${streamId}/thumbnail_url`);
  return data;
};

const useGetThumbnail = (streamId) => {
  return useQuery({
    queryKey: ['thumbnail', streamId],
    queryFn: () => getThumbnail(streamId),
    refetchOnWindowFocus: false,
  });
};

export default useGetThumbnail;
