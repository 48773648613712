import api from '../config/api';
import { useQuery } from '@tanstack/react-query';
import isGuestUser from './isGuestUser';

const getSetting = async () => {
  if (isGuestUser()) return { data: null };
  const { data } = await api.get('/api/user/settings');
  return data;
};

const useSetting = () => {
  return useQuery({ queryKey: ['setting'], queryFn: getSetting });
};

export default useSetting;
