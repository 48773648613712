// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --player-height: 100vh; /* Default to 100vh */
}

/* If 100dvh is supported, set --player-height to 100dvh */
@supports (height: 100dvh) {
  :root {
    --player-height: 100dvh;
  }
}
* {
  font-family: 'Urbanist', sans-serif !important;
}

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* This is for bottom drawer   */
  padding-right: 0 !important;
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #fae80a !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  background: #fae80a !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,qBAAqB;AAC/C;;AAEA,0DAA0D;AAC1D;EACE;IACE,uBAAuB;EACzB;AACF;AACA;EACE,8CAA8C;AAChD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;EAClC,gCAAgC;EAChC,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,8BAA8B;AAChC;AACA;;EAEE,8BAA8B;AAChC","sourcesContent":[":root {\n  --player-height: 100vh; /* Default to 100vh */\n}\n\n/* If 100dvh is supported, set --player-height to 100dvh */\n@supports (height: 100dvh) {\n  :root {\n    --player-height: 100dvh;\n  }\n}\n* {\n  font-family: 'Urbanist', sans-serif !important;\n}\n\nhtml {\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  /* This is for bottom drawer   */\n  padding-right: 0 !important;\n}\n.swiper-pagination-bullet-active {\n  color: #fff;\n  background: #fae80a !important;\n}\n.swiper-horizontal > .swiper-pagination-bullets,\n.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {\n  background: #fae80a !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
