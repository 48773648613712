import { Box } from '@mui/material';
import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import api from '../../config/api';
import Loading from '../../components/Loading/Loading';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import TopStreamerCard from './TopStreamerCard';
import Stack from '@mui/material/Stack';
import { TOP_STREAMER_CARD_HEIGHT } from '../../utils/constant';
import NoStreamerCard from './NoStreamerCard';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';

const TopStreamersComponent = ({ setTopStreamersComponentHeight, searchQuery }) => {
  const getTopStreamers = async ({ pageParam, queryKey }) => {
    const [_, { query }] = queryKey;
    const URL =
      searchQuery.index === 0
        ? `/api/user/top-streamers?page=${pageParam}&size=10&search=${query}`
        : `/api/user/top-streamers?page=${pageParam}&size=10`;
    const { data } = await api.get(URL);
    setTopStreamersComponentHeight && setTopStreamersComponentHeight(data.data.totalCount * TOP_STREAMER_CARD_HEIGHT + 45); // 45 request text height
    return data;
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['infiniteTopStreamers', { query: searchQuery.query }],
    queryFn: getTopStreamers,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages?.length * 10;
      const totalData = lastPage?.data?.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });

  const topStreamers = useInfiniteQueryReducedResult(data);

  if (isLoading) return <Loading />;
  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <MenuHeader title={'Streamers'} />
      <Box sx={{ overflowX: 'hidden' }}>
        <Stack spacing={1.2}>
          {topStreamers?.length === 0 && <NoStreamerCard />}
          {topStreamers?.map((topStreamer, index) => (
            <TopStreamerCard
              key={topStreamer.uid}
              data={topStreamer}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              index={index}
              totalCount={topStreamers?.length}
            />
          ))}
        </Stack>
      </Box>
      {/*<CustomPagination handleChange={handleChange} page={page} size={topStreamers?.data?.totalCount} />*/}
    </Box>
  );
};

export default TopStreamersComponent;
