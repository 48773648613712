import api from '../config/api';
import { useQuery } from '@tanstack/react-query';
import isGuestUser from './isGuestUser';

const isUserLiked = async (taskId) => {
  const { data } = await api.get(`/api/user/task/${taskId}/like`);
  return data;
};

const useIsUserLiked = (taskId) => {
  return useQuery({
    queryKey: ['isUserLiked', taskId],
    queryFn: () => isUserLiked(taskId),
    enabled: !isGuestUser()
  });
};
export default useIsUserLiked;
