import React from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Routes from '../../routes';
import ForgetPasswordImage from '../../assets/images/ForgetPasswordImage';
import api from '../../config/api';
import { toast } from 'react-toastify';

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const handlePasswordReset = async (value) => {
    const { email } = value;
    try {
      setIsLoading(true);
      const { data: isEmailExist } = await api.get(`/api/public/validate?email=${email}`);
      if (!isEmailExist?.data?.isEmail) {
        setIsLoading(false);
        toast.error('Email not registered');
      } else {
        await api.get(`/api/public/verification?type=resetPassword&email=${email}`);
        toast.success('Verification code has been sent to your email');
        navigate(Routes.FORGET_PASSWORD_CODE_VERIFICATION.replace(':email', email));
      }
    } catch (e) {
      toast.error("We can't find a user with that email address");
    }
  };
  return (
    <Box
      pt={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100dvh',
        width: '100%',
      }}
    >
      <Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ForgetPasswordImage />
        </Box>
        <Stack spacing={1}>
          <Typography variant={'h3_dark'} align={'center'}>
            Forgot Password?
          </Typography>
          <Typography variant={'h5'} align={'center'}>
            Enter your email below to receive your password reset instructions
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(handlePasswordReset)}>
          <Stack spacing={3} sx={{ mt: 3, textAlign: 'center' }}>
            <CustomTextField
              name={'email'}
              label={'Email Address'}
              control={control}
              rules={{ required: 'Email is required' }}
              type={'email'}
            />
            <Button variant='contained' type='submit' size='large'>
              Next
            </Button>
          </Stack>
        </form>
      </Box>

      <Typography
        variant={'h5_dark'}
        component={Link}
        to={Routes.LOGIN}
        sx={{ width: '100%', margin: '20px 0px 40px 0px' }}
        textAlign={'center'}
      >
        Back to log in
      </Typography>
    </Box>
  );
};

export default ForgetPassword;
