import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  signInWithRedirect,
} from 'firebase/auth';

const ENV = process.env.REACT_APP_ENV;
let firebaseConfig;
if (ENV === 'DEV')
  firebaseConfig = {
    apiKey: 'AIzaSyD8FMakbv7PCkupjOJ2Az44W4HOGra1eeU',
    // authDomain: 'dev-auth.bunpun.com',
    authDomain: 'dev-app.bunpun.com',
    projectId: 'bunpun-7002e',
    storageBucket: 'bunpun-7002e.appspot.com',
    messagingSenderId: '894332707831',
    appId: '1:894332707831:web:806d2d23772519eb3744f2',
    measurementId: 'G-R9BDXPZ900',
  };
else if (ENV === 'UAT') {
  firebaseConfig = {
    apiKey: 'AIzaSyC8pdufYzvu97VHLiWNPc_ZsxJQLXFtRIE',
    // authDomain: 'staging-auth.bunpun.com',
    authDomain: 'staging-app.bunpun.com',
    projectId: 'staging-bunpun',
    storageBucket: 'staging-bunpun.appspot.com',
    messagingSenderId: '1000222858093',
    appId: '1:1000222858093:web:87f225b93da0c699c2d864',
    measurementId: 'G-SSVNQFTVN3',
  };
} else if (ENV === 'PROD') {
  firebaseConfig = {
    apiKey: 'AIzaSyCCOJtieFGYO9tk0N92FcpyqJDRadSou1w',
    // authDomain: 'auth.bunpun.com',
    authDomain: 'app.bunpun.com',
    projectId: 'bunpun-c04f6',
    storageBucket: 'bunpun-c04f6.appspot.com',
    messagingSenderId: '830474971462',
    appId: '1:830474971462:web:6e43b26d452df66af11d47',
    measurementId: 'G-N1X4366MX2',
  };
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const signInWithGoogleRedirect = async () => {
  try {
    await signInWithRedirect(auth, googleAuthProvider);
  } catch (error) {
    throw error;
  }
};

export {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
  analytics,
  onAuthStateChanged,
  signOut,
  signInWithGoogleRedirect,
};
