import { useQuery } from '@tanstack/react-query';
import api from '../config/api';
import { LIVE_STREAM_STATUS } from '../utils/constant';
// import liveStreamTransformer from '../transformers/liveStreamTransformer';

const liveStreamStatus = async (streamId, data) => {
  const { data: apiData } = await api.post(`/api/stream/${streamId}/state`, data);
  // return liveStreamTransformer(apiData);
  return apiData?.data;
};

const useLiveStreamState = (streamId, data) => {
  return useQuery({
    queryKey: ['start_live'],
    queryFn: () => liveStreamStatus(streamId, data),
    refetchOnWindowFocus: false,
    refetchInterval: ({ state }) => {
      const res = state?.data?.data;
      if (res?.live_stream?.state === LIVE_STREAM_STATUS.STOPPED) {
        return false;
      }
      return 5000;
    },
    enabled: !!streamId,
  });
};

export default useLiveStreamState;
