import React, { useState } from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import Box from '@mui/material/Box';
import ConfirmBottomDrawer from './ConfirmBottomDrawer';
import RequestVideoForm from './RequestVideoForm';
import { taskDetails } from '../../store';
import useSetting from '../../hooks/useSetting';

const RequestVideoPage = () => {
  const [bottomDrawerState, setBottomDrawerState] = React.useState(false);
  const [deadline, setDeadline] = useState({
    date: null,
    timeZone: '',
  });
  const { data: setting } = useSetting();

  const onSubmit = async (value) => {
    if (deadline.date !== null) {
      const fee = (parseFloat(value.amount) * setting?.data?.platformFeePercentage) / 100;
      taskDetails.value = {
        ...value,
        requestAt: deadline.date.toISOString(),
        type: 'REQUEST',
        duration: value.duration.value,
        fee: fee.toFixed(2),
        timeZone: deadline.timeZone,
      };
      console.log(taskDetails.value);
      setBottomDrawerState(true);
    }
  };

  return (
    <>
      <Box sx={{ pb: 10 }}>
        <TopNavigation title={'Request'} />
        <TopNavigationSpace />
        <RequestVideoForm onSubmit={onSubmit} deadline={deadline} setDeadline={setDeadline} />
      </Box>
      <ConfirmBottomDrawer bottomDrawerState={bottomDrawerState} setBottomDrawerState={setBottomDrawerState} />
    </>
  );
};
export default RequestVideoPage;
