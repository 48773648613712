export const calculateTotalGifts = (obj) => {
  return Object.values(obj).reduce((acc, val) => acc + val, 0);
};

export default function liveStreamTransformer(data) {
  console.log('data', data);
  return {
    liveStreamState: data.data.live_stream.state,
    viewers: data.data.live_stream.viewers,
    chatCount: data.data.task.chats,
    likeCount: data.data.task.likes,
    giftCount: calculateTotalGifts(data.data.task.chats),
  };
}
