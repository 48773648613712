import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../config/api';
import { useQuery } from '@tanstack/react-query';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import Button from '@mui/material/Button';
import Loading from '../../components/Loading/Loading';
import StreamerVideos from './StreamerVideos';
import { ErrorBoundary } from './Error';
import ReactGA from 'react-ga4';
import ROUTES from '../../routes';
import LoginModal from '../../components/LoginModal/Login';
import isGuestUser from '../../hooks/isGuestUser';

const getProfile = async (streamId) => {
  const { data } = await api.get(`/api/user/profile?page=1&size=30&id=${streamId}&filter=completed`);
  return data;
};

const StreamerProfile = () => {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  ReactGA.send({ hitType: 'pageview', page: ROUTES.STREAMER_PROFILE, title: 'Refer and Earn Page' });
  const steamId = useParams().id;
  const navigate = useNavigate();
  const { data: profile, isLoading } = useQuery({ queryKey: ['streamer_profile'], queryFn: () => getProfile(steamId) });

  if (isLoading) return <Loading />;

  return (
    <>
      <Box sx={{ pb: 8 }}>
        <TopNavigation title={profile?.data?.name} />
        <TopNavigationSpace />
        <Stack
          direction='row'
          sx={{
            py: 1.5,
            mx: { sm: '-24px', xs: '-16px' },
            px: { sm: '24px', xs: '16px' },
            // boxShadow: '0px -3px 20px 0px #0000001A',
          }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack direction='row' gap={1} alignItems='center'>
            <CustomAvatar avatarUrl={profile?.data?.profile?.avatarUrl} name={profile?.data?.name} size={64} />
            <Stack>
              <Typography variant='h5_bold'>{profile?.data?.name}</Typography>
              <Typography variant={'h6_semibold'} sx={{ color: '#11111180' }}>
                {profile?.data?.profile?.streamCount} Videos Created
              </Typography>
            </Stack>
          </Stack>

          <Button
            variant='contained'
            size='small'
            onClick={() => (isGuestUser() ? setOpenLoginModal(true) : navigate(`/${steamId}/request`))}
          >
            Request
          </Button>
        </Stack>
        <Stack spacing={1.5} mt={1.5}>
          <Typography variant='h6'>{profile?.data?.profile?.description}</Typography>
          <Typography variant='h6_dark'>Videos created</Typography>
          <ErrorBoundary>
            <StreamerVideos steamId={steamId} setOpenLoginModal={setOpenLoginModal} />
          </ErrorBoundary>
        </Stack>
      </Box>
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
};

export default StreamerProfile;
