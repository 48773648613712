import React from 'react';
import MenuHeader from '../../MenuHeader';
import ROUTES from '../../../../routes';
import { Box } from '@mui/material';
import NoStreamerCard from '../../../TopStreamersPage/NoStreamerCard';
import { useNavigate } from 'react-router-dom';
import LoadingSmall from '../../../../components/Loading/LoadingSmall';
import 'swiper/css';
import PopularVideoCard from './PopularVideoCard';
import useGetPopularVideos from '../../../../hooks/useGetPopularVideos';

const PopularVideos = () => {
  const navigate = useNavigate();
  const { data: popularVideos, isLoading } = useGetPopularVideos();
  if (isLoading) return <LoadingSmall />;
  return (
    <Box mt={4}>
      <MenuHeader
        title={'Popular Videos'}
        onClick={() => navigate(ROUTES.POPULAR_VIDEOS)}
        isViewAll={popularVideos?.data?.totalCount > 0}
      />
      <Box mt={2}>
        {popularVideos?.data?.totalCount === 0 && <NoStreamerCard />}
        <swiper-container slides-per-view={2} style={{ width: '100%', height: '100%' }} space-between='10'>
          {popularVideos?.data?.result?.map((popularVideo) => (
            <swiper-slide key={popularVideo._id} style={{ width: '100%', opacity: 1 }}>
              <PopularVideoCard key={popularVideo.title} data={popularVideo} />
            </swiper-slide>
          ))}
        </swiper-container>
      </Box>
    </Box>
  );
};

export default PopularVideos;
