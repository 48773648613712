import * as React from 'react';

function DocumentUploadIcon(props) {
  return (
    <svg width={23} height={23} viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.111 12.639v-2.454l1.852 1.852M9.111 15.74v-.925M9.111 10.185L7.26 12.037M2.63 8.333c0-4.63 1.851-6.481 6.481-6.481h4.63'
        stroke='#1E1D25'
        strokeWidth={1.38889}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.148 9.26v4.629c0 4.63-1.852 6.481-6.481 6.481H9.11c-4.63 0-6.481-1.852-6.481-6.481v-1.87'
        stroke='#1E1D25'
        strokeWidth={1.38889}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.444 9.26c-2.777 0-3.703-.927-3.703-3.704V1.852l7.407 7.407'
        stroke='#010101'
        strokeWidth={1.38889}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default DocumentUploadIcon;
