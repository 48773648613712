import React from 'react';
import Stack from '@mui/material/Stack';
import NoPageFoundImage from '../../assets/images/NoPageFoundImage';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';

const NoPageFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Stack sx={{ minHeight: '100dvh', justifyContent: 'center', alignItems: 'center' }} spacing={4}>
      <NoPageFoundImage />
      <Button variant='contained' sx={{ width: '50%' }} onClick={() => navigate(ROUTES.WELCOME)}>
        Go Back
      </Button>
    </Stack>
  );
};

export default NoPageFoundPage;
