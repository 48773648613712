import React, { useState } from 'react';
import dayjs from 'dayjs';
import generateCalendar, { months } from './calendar';
import { Box, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import LeftArrowIcon from './LeftArrowIcon';
import RightArrowIcon from './RightArrowIcon';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css';
import Button from '@mui/material/Button';
import BrokenCalenderIcon from '../../assets/icons/BrokenCalenderIcon';
import BrokenClockIcon from '../../assets/icons/BrokenClockIcon';
import SelectTime from './SelectTime';
import { toast } from 'react-toastify';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomCalendar = ({ dateAndTime, setDateAndTime, handleClose }) => {
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const currentDate = dayjs();
  const lastDate = currentDate.add(90, 'day');
  const isSelectedTimeAcceptable = dateAndTime?.date?.isAfter(currentDate);
  const [today, setToday] = useState(currentDate);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (dateAndTime.date) setValue(newValue);
  };

  return (
    <Stack spacing={1} alignItems='center'>
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <Typography variant='h6'>
          {dateAndTime.date ? dayjs(dateAndTime.date).format('DD MMM YYYY') : '-- --'}
        </Typography>
        <Typography variant='h6'>{dateAndTime.date ? dayjs(dateAndTime.date).format('HH : mm') : '-- : --'}</Typography>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example' variant='fullWidth'>
          <Tab icon={<BrokenCalenderIcon />} {...a11yProps(0)} />
          <Tab icon={<BrokenClockIcon />} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Stack spacing={1}>
          <Stack direction='row' justifyContent='center' alignItems='center' gap={3}>
            <IconButton
              onClick={() => setToday(today.subtract(1, 'month'))}
              disabled={today.month() <= currentDate.month()}
            >
              <LeftArrowIcon variant={today.month() <= currentDate.month() ? 'light' : 'dark'} />
            </IconButton>
            <Typography variant='h5_dark'>
              {months[today.month()]} {today.year()}
            </Typography>
            <IconButton onClick={() => setToday(today.add(1, 'month'))} disabled={lastDate.diff(today, 'day') <= 0}>
              <RightArrowIcon variant={lastDate.diff(today, 'day') <= 0 ? 'light' : 'dark'} />
            </IconButton>
          </Stack>
          <Grid container>
            {days.map((day, index) => (
              <Grid item xs={1.7} key={index} display='flex' justifyContent='center'>
                <Typography
                  variant='caption_semibold'
                  sx={{
                    height: 30,
                    width: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                  }}
                >
                  {day}
                </Typography>
              </Grid>
            ))}
            {generateCalendar(today.month(), today.year()).map(({ currentMonth, date, today }, index) => (
              <Grid item xs={1.7} key={index} display='flex' justifyContent='center'>
                <Button
                  // variant='h5_bold'
                  size={'small'}
                  sx={{
                    color: '#000',
                    p: 0,
                    minWidth: 30,
                    opacity: currentMonth ? 1 : 0.5,
                    border: (theme) => (today ? `1px solid ${theme.palette.primary.main}` : ''),
                    height: 30,
                    width: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    backgroundColor: (theme) =>
                      dateAndTime?.date?.toDate()?.toDateString() === date?.toDate()?.toDateString()
                        ? `${theme.palette.primary.main}`
                        : '',
                    '&:hover': {
                      backgroundColor: (theme) => `${theme.palette.primary.light}`,
                    },
                  }}
                  onClick={() => {
                    setDateAndTime((prev) => ({
                      ...prev,
                      date: date.set('hour', 0).set('minute', 0).set('second', 0),
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }));
                    handleChange('', 1);
                  }}
                  disabled={date.diff(currentDate, 'day') < 0 || lastDate.diff(date, 'day') < 0}
                >
                  {date.date()}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SelectTime dateAndTime={dateAndTime} setDateAndTime={setDateAndTime} />
      </CustomTabPanel>
      <Stack direction='row'>
        <Button
          onClick={() => {
            setDateAndTime({
              date: null,
              timezone: '',
            });
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            // const hour = parseInt(hours[hoursRef?.current?.swiper?.activeIndex]);
            // const minute = parseInt(minutes[minutesRef?.current?.swiper?.activeIndex]);
            // const timeZone = timezoneRef?.current?.swiper?.activeIndex;

            if (!isSelectedTimeAcceptable) {
              toast.error('Oops! Looks like you selected a past date and time. Please try again.');
            } else {
              handleClose();
            }

            // console.log(dayjs.tz(selectDate, options[timeZoneSliderIndex].value).format('hh:mm'));
          }}
        >
          OK
        </Button>
      </Stack>
    </Stack>
  );
};

export default CustomCalendar;
