import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';

const LikeCommentShareBox = ({ Icon, value, onClick, variant }) => {
  return (
    <Box textAlign='center'>
      <IconButton
        sx={{ backgroundColor: '#0000004D', '&:hover': { backgroundColor: '#0000004D', color: '#fff' } }}
        onClick={onClick}
      >
        <Icon variant={variant} />
      </IconButton>
      <Box
        sx={{
          borderRadius: '50%',
        }}
      >
        <Typography
          align='center'
          variant='h6_bold'
          sx={{
            color: '#fff',
            filter: 'drop-shadow(0px 0px 3px #00000066)',
            textShadow: '0px 0px 3px #00000066',
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default LikeCommentShareBox;
